import axios from 'axios';

import { Address, AddressUnassigned, DefaultApiResponse } from './_types';

const endpoints = {
  addressData: '/api/v1/address-data/',
};

export type UpdateAddressDataInput = AddressUnassigned;

type UpdateAddressDataQuery = (data: UpdateAddressDataInput) => Promise<DefaultApiResponse<Address>>;

const patients = {
  postAddressData:
    (id: number): UpdateAddressDataQuery =>
    data =>
      axios.post<UpdateAddressDataQuery, DefaultApiResponse<Address>>(`${endpoints.addressData}${id}/`, data),
  putAddressData:
    (id: number): UpdateAddressDataQuery =>
    data =>
      axios.put<UpdateAddressDataQuery, DefaultApiResponse<Address>>(`${endpoints.addressData}${id}/`, data),
  patchAddressData:
    (id: number): UpdateAddressDataQuery =>
    data =>
      axios.patch<UpdateAddressDataQuery, DefaultApiResponse<Address>>(`${endpoints.addressData}${id}/`, data),
};

export default patients;
