import type { FC, MouseEvent } from 'react';
import { useState } from 'react';

import { Typography, ButtonBase, Popper, Fade, Paper, Button, ClickAwayListener } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LogoutIcon } from 'assets/icons/power.svg';
import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import Chevron from 'components/UI/atoms/Chevron/Chevron';
import { DIRECTIONS } from 'constants/_types/Direction';
import menuMessages from 'translations/specific/menu.mjs';

import useStyles from './UserMenuDropdown.styles';

const UserMenuDropdown: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { logout } = useAuthContext();
  const { userInfo } = useAuthContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  const isOpen = Boolean(anchorEl);
  const toggleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onClose = () => setAnchorEl(null);

  const { classes } = useStyles();
  return (
    <>
      <ButtonBase className={classes.root} data-testid='main_user_dropdown' disableRipple onClick={toggleOpen}>
        <div className={classes.loggedInfo}>
          <Typography variant='caption'>{t(menuMessages.loggedUser)}</Typography>
          <Typography variant='subtitle3'>{userInfo.email}</Typography>
        </div>
        <Chevron
          className='onlyUpSm'
          color={theme.palette.secondary.main}
          direction={{ base: DIRECTIONS.bottom, alternate: DIRECTIONS.top }}
          isAlternated={isOpen}
        />
      </ButtonBase>
      <Popper anchorEl={anchorEl} open={isOpen} placement='bottom-end' transition>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={onClose}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper className={classes.popperContent}>
                <Button className={classes.button} color='secondary' data-testid='logout_button' onClick={logout}>
                  <LogoutIcon />
                  <Typography variant='subtitle1'>{t(menuMessages.logout)}</Typography>
                </Button>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default UserMenuDropdown;
