export type Dialogs = 'clauses' | 'passwordChange' | 'patient' | 'prescription' | 'referral' | 'stationaryVisit' | 'teleconsultation';

export const DIALOGS: { [key in Dialogs]: Dialogs } = {
  clauses: 'clauses',
  passwordChange: 'passwordChange',
  patient: 'patient',
  prescription: 'prescription',
  referral: 'referral',
  stationaryVisit: 'stationaryVisit',
  teleconsultation: 'teleconsultation',
};
