import { makeStyles } from 'tss-react/mui';

const TEXT_SECTION = 'textSection';
const PLACEHOLDER_SECTION = 'placeholderSection';
const STEPPER_SECTION = 'stepperSection';

const useStyles = makeStyles<{ hasStepper: boolean }>()((theme, { hasStepper }) => ({
  root: hasStepper
    ? {
        display: 'grid',
        gridTemplateColumns: '1.5fr 1fr 1.5fr',
        gridTemplateAreas: `"${TEXT_SECTION} ${STEPPER_SECTION} ${PLACEHOLDER_SECTION}"`,
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
          gridTemplateColumns: '1fr',
          gridTemplateAreas: `
            "${TEXT_SECTION}"
            "${PLACEHOLDER_SECTION}"
            "${STEPPER_SECTION}"
          `,
          marginBottom: theme.spacing(3),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      }
    : {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
          alignItems: 'flex-start',
          flexDirection: 'column',
        },
        [theme.breakpoints.down('sm')]: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      },

  textContainer: {
    gridArea: TEXT_SECTION,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1.75),
    },
  },

  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  iconContainer: {
    borderRadius: theme.shape.borderRadius,
    width: '48px',
    height: '48px',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(3),
  },

  title: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem',
      lineHeight: '1.875rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      lineHeight: '2.0625rem',
    },
  },

  divider: {
    gridArea: PLACEHOLDER_SECTION,
    width: '100%',
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      margin: theme.spacing(3, 0),
    },
  },

  stepper: {
    gridArea: STEPPER_SECTION,
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useStyles;
