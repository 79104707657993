import type { FC } from 'react';

import { Tooltip, TooltipProps, Typography } from '@mui/material';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

import useStyles from './InfoIconWithTooltip.styles';

export type Props = {
  content: string;
  tooltipProps?: Partial<TooltipProps>;
};

const InfoIconWithTooltip: FC<Props> = ({ content, tooltipProps = {} }) => {
  const { classes } = useStyles();
  return (
    <Tooltip {...tooltipProps} title={<Typography variant='subtitle3'>{content}</Typography>}>
      <span className={classes.iconWrapper} data-testid='InfoIconWithTooltip_iconWrapper'>
        <InfoIcon className={classes.icon} />
      </span>
    </Tooltip>
  );
};

export default InfoIconWithTooltip;
