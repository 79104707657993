import type { FC, ChangeEvent } from 'react';

import { Checkbox, FormControl, FormControlLabel, Typography } from '@mui/material';

import { ReactComponent as CheckedIcon } from 'assets/icons/checkboxes/checked.svg';
import { ReactComponent as RadioCheckedIcon } from 'assets/icons/checkboxes/radio_checked.svg';
import { ReactComponent as RadioUncheckedIcon } from 'assets/icons/checkboxes/radio_unchecked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/icons/checkboxes/unchecked.svg';
import InputFooter from 'components/UI/atoms/InputFooter/InputFooter';

import useStyles from './InputCheckbox.styles';

export type Props = {
  label: string | JSX.Element;
  title?: string;
  error?: string;
  disabled?: boolean;
  tiny?: boolean;
  value?: boolean;
  required?: boolean;
  inlineError?: boolean;
  hasRadioIcon?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  hideAsterisk?: boolean;
};

const InputCheckbox: FC<Props> = ({
  label,
  title,
  error,
  tiny,
  required,
  inlineError,
  hasRadioIcon = false,
  hideAsterisk = false,
  onChange,
  ...field
}) => {
  const { classes } = useStyles({ tiny, error: !!error });
  return (
    <div>
      <FormControl className={classes.root}>
        {label && (
          <Typography className={classes.title} variant='body1'>
            {title}
          </Typography>
        )}
        <FormControlLabel
          classes={{ label: classes.label }}
          control={
            <Checkbox
              checked={field.value}
              checkedIcon={hasRadioIcon ? <RadioCheckedIcon className={classes.icon} /> : <CheckedIcon className={classes.icon} />}
              color='secondary'
              icon={hasRadioIcon ? <RadioUncheckedIcon className={classes.icon} /> : <UncheckedIcon className={classes.icon} />}
              onChange={onChange}
              {...field}
            />
          }
          label={
            required ? (
              <span>
                {label}
                {!hideAsterisk && <span className={classes.asterisk}>*</span>}
                {inlineError && <InputFooter error={error} />}
              </span>
            ) : (
              label
            )
          }
        />
      </FormControl>
      {!inlineError && <InputFooter className={classes.footer} error={error} />}
    </div>
  );
};

export default InputCheckbox;
