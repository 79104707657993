import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  buttonsContainer: {
    position: 'sticky',
    bottom: 0,
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    background: theme.palette.background.default,
  },

  buttons: {
    display: 'flex',
    justifyContent: 'stretch',
    gap: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateRows: '1fr',
      gridTemplateColumns: '1fr 1fr',
      gridGap: theme.spacing(4),
    },
  },
}));

export default useStyles;
