import type { FC, ReactNode } from 'react';

import { Container } from '@mui/material';
import { useLocation } from 'react-router-dom';

import BottomFixedContainer from 'components/UI/molecules/BottomFixedContainer/BottomFixedContainer';
import BreadcrumbsSection from 'components/UI/molecules/BreadcrumbsSection/BreadcrumbsSection';
import AlertWithOptionalActions from 'components/UI/organisms/AlertWithOptionalActions/AlertWithOptionalActions';
import PATHS from 'constants/router/PATHS';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';
import { getMobileOperatingSystem } from 'services/getMobileOperatingSystem/getMobileOperatingSystem';
import useBottomFixedContainerStorage from 'storages/bottomFixedContainerStorage';

import useStyles from './MainContentWrapper.styles';

type Props = {
  children: ReactNode;
  isReducedPadding?: boolean;
  noPadding?: boolean;
  extraContainer?: boolean;
  isFullHeight?: boolean;
};

const MainContentWrapper: FC<Props> = ({
  children,
  isReducedPadding = false,
  noPadding = false,
  extraContainer = false,
  isFullHeight = false,
}) => {
  const { breadcrumbs, alertMessages } = usePageConfig() || {};
  const { buttonsBarHeight } = useBottomFixedContainerStorage();
  // FOR v.0.4 ONLY
  const { pathname } = useLocation();
  const shouldAdjustMobileViewportHeight = pathname === PATHS.SYMPTOM_CHECKER;
  const mobileOS = getMobileOperatingSystem();
  //
  const { classes } = useStyles({
    isReducedPadding,
    noPadding,
    extraContainer,
    isFullHeight,
    buttonsBarHeight,
    shouldAdjustMobileViewportHeight,
    isIOS: mobileOS === 'iOS',
  });

  return (
    <div className={classes.root}>
      <Container className={classes.main} component='main'>
        {!!breadcrumbs?.length && <BreadcrumbsSection breadcrumbs={breadcrumbs} />}
        <Container className={classes.extraContainer}>
          {!!alertMessages?.length && (
            <div className={classes.messages}>
              {alertMessages.length
                ? alertMessages.map((message, index) => <AlertWithOptionalActions key={index.toString()} {...message} />)
                : null}
            </div>
          )}
          {children}
        </Container>
        <BottomFixedContainer />
      </Container>
    </div>
  );
};

export default MainContentWrapper;
