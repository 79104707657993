import type { CoreServiceSubtypeDetails, CoreServiceSubtypeDetailsDTO } from 'api/coreServiceSubtypes/types';
import parseDynamicTranslatableName from 'services/parseDynamicTranslatableName/parseDynamicTranslatableName';

export const parseServiceSubtypeDetailsForFE = (rawData: CoreServiceSubtypeDetailsDTO): CoreServiceSubtypeDetails => ({
  id: rawData.id,
  masterType: rawData.master_type,
  name: parseDynamicTranslatableName(rawData.name),
  serviceOrigin: rawData.service_origin,
  ordering: rawData.ordering,
});
