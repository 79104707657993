import { makeStyles } from 'tss-react/mui';

import { FORM_CARD_MAX_WIDTH } from 'constants/styles/FORM_CARD_MAX_WIDTH';

import { BASKET_AREA, MAIN_FORM_AREA } from '../../_constants/gridAreas';

const useStyles = makeStyles()(theme => ({
  mainForm: {
    gridArea: MAIN_FORM_AREA,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  payerFormWrapper: {
    maxWidth: FORM_CARD_MAX_WIDTH,
    padding: theme.spacing(5),
    marginBottom: theme.spacing(4),
    width: '100%',
  },

  basket: {
    gridArea: BASKET_AREA,
    background: theme.palette.background.default,
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
