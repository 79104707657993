import type { FC } from 'react';
import { useMemo } from 'react';

import { Control, Controller, ValidationRule } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputDate from 'components/UI/molecules/InputDate/InputDate';
import validationMessages from 'translations/common/validation.mjs';

import { resolveDateValidation } from './_services/resolveDateValidation/resolveDateValidation';

export interface DateInputProps {
  fullWidth?: boolean;
  multiline?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  timePicker?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

export type Props = {
  label: string;
  name: string;
  control: Control<any>;
  helperText?: string;
  required?: boolean;
  defaultValue?: Date | null;
  rules?: ValidationRule<any>;
  disablePast?: boolean;
  disableFuture?: boolean;
  inputProps?: DateInputProps;
};

const FormInputDate: FC<Props> = ({
  label,
  control,
  helperText,
  name,
  defaultValue = null,
  inputProps = {},
  rules = {},
  required = false,
  disablePast = false,
  disableFuture = false,
}) => {
  const { t, i18n } = useTranslation();

  const rulesProp: { [key: string]: ValidationRule } = useMemo(
    () => ({
      required: { value: required, message: t(validationMessages.required_field) },
      ...rules,
      validate: (value: Date) => resolveDateValidation({ value, t, rules, control, disablePast, disableFuture }),
    }),
    [rules, required, i18n.language],
  );

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field, fieldState }) => (
        <InputDate
          disableFuture={disableFuture}
          disablePast={disablePast}
          error={fieldState.error?.message}
          helperText={helperText}
          label={label}
          {...inputProps}
          {...field}
        />
      )}
      rules={rulesProp}
    />
  );
};

export default FormInputDate;
