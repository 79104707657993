import type { FC, ReactNode } from 'react';
import { createContext, useState, useContext, useRef, useMemo } from 'react';

import { Dialog, Button, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';

import generalMessages from 'translations/common/general.mjs';

export type ConfirmationOptions = {
  title?: string;
  body?: string;
  infoOnly?: boolean;
  buttonLabels?: { onTrue?: string; onFalse?: string };
};
type ShowConfirmationDialog = (options: ConfirmationOptions) => Promise<boolean>;

type Props = {
  children: ReactNode;
};

type ConfirmationDialogContextType = {
  showConfirmationDialog: ShowConfirmationDialog;
};

const ConfirmationDialogContext = createContext({} as ConfirmationDialogContextType);

const { Provider } = ConfirmationDialogContext;

const ConfirmationDialogProvider: FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [confirmationOptions, setConfirmationOptions] = useState<ConfirmationOptions | null>();

  const awaitingConfirmation = useRef<any>(null);

  const closeModal = () => {
    setOpen(false);
    setTimeout(() => setConfirmationOptions(null), 150);
  };

  const showConfirmationDialog: ShowConfirmationDialog = options => {
    setOpen(true);
    const defaultConfig = { infoOnly: false };
    setConfirmationOptions({ ...defaultConfig, ...options });
    return new Promise(resolve => {
      awaitingConfirmation.current = { resolve };
    });
  };

  const onCancel = () => {
    if (awaitingConfirmation.current) {
      awaitingConfirmation.current.resolve(false);
    }

    closeModal();
  };

  const onConfirm = () => {
    if (awaitingConfirmation.current) {
      awaitingConfirmation.current.resolve(true);
    }

    closeModal();
  };

  const value = useMemo(() => ({ showConfirmationDialog }), [showConfirmationDialog]);

  return (
    <Provider value={value}>
      {children}
      <Dialog
        aria-describedby='alert-dialog-description'
        aria-labelledby='alert-dialog-title'
        fullWidth
        maxWidth='xs'
        open={open}
        onClose={onCancel}
      >
        {confirmationOptions?.title && <DialogTitle id='alert-dialog-title'>{confirmationOptions.title}</DialogTitle>}
        {confirmationOptions?.body && (
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>{confirmationOptions.body}</DialogContentText>
          </DialogContent>
        )}
        {confirmationOptions?.infoOnly ? (
          <DialogActions>
            <Button autoFocus variant='contained' onClick={onConfirm}>
              {t(generalMessages.ok)}
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button variant='outlined' onClick={onCancel}>
              {confirmationOptions?.buttonLabels?.onFalse || t(generalMessages.no)}
            </Button>
            <Button autoFocus variant='contained' onClick={onConfirm}>
              {confirmationOptions?.buttonLabels?.onTrue || t(generalMessages.yes)}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Provider>
  );
};

export const useConfirmationDialogContext = () => useContext(ConfirmationDialogContext);

export default ConfirmationDialogProvider;
