import { PureComponent, type ChangeEvent } from 'react';

import { FormControl, InputBaseComponentProps, InputLabel, OutlinedInput } from '@mui/material';

import InputFooter from 'components/UI/atoms/InputFooter/InputFooter';

import { TextMask } from './components/TextMask';

export type Mask = {
  pattern: string;
  definitions: {
    [key: string]: RegExp;
  };
};

export type Props = {
  label: string;
  name: string;
  fullWidth?: boolean;
  error?: string | boolean;
  multiline?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  helperText?: string;
  mask?: Mask;
  value?: string;
  type?: 'text' | 'number';
  onChange?: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  inputProps?: InputBaseComponentProps;
  classes?: {
    label: string;
    input: string;
  };
};

type InputProps = InputBaseComponentProps & { mask?: Mask };

class InputText extends PureComponent<Props> {
  customShrink = (): boolean | undefined => {
    const { value, mask } = this.props;
    return mask ? !!value : undefined;
  };

  getMergedInputProps = (): InputProps => {
    const { mask, name, inputProps } = this.props;
    const result: InputProps = {
      ...inputProps,
      'data-testid': `${name}_input`,
    };

    if (mask) result.mask = mask;
    return result;
  };

  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    onChange?.(e);
  };

  render() {
    const {
      classes,
      label,
      multiline,
      error,
      helperText,
      fullWidth = true,
      readOnly,
      disabled,
      mask,
      type = 'text',
      ...field
    } = this.props;

    return (
      <FormControl disabled={disabled} error={!!error} fullWidth={fullWidth} variant='outlined'>
        <InputLabel className={classes?.label} disabled={disabled} htmlFor={`text_input_${label}`} shrink={this.customShrink()}>
          {label}
        </InputLabel>
        <OutlinedInput
          className={classes?.input}
          color='secondary'
          disabled={disabled}
          id={`text_input_${label}`}
          inputComponent={mask ? (TextMask as any) : undefined}
          label={label}
          multiline={multiline}
          notched={false}
          readOnly={readOnly}
          type={type}
          {...field}
          inputProps={this.getMergedInputProps()}
          value={field.value}
          onChange={this.handleChange}
        />
        <InputFooter error={error} helperText={helperText} name={field.name} />
      </FormControl>
    );
  }
}

export default InputText;
