import { alpha, Components } from '@mui/material';
import { Theme } from '@mui/material/styles';

import ICON_SIZES_STYLES from 'constants/styles/ICON_SIZES_STYLES';

const components: Components<Theme> = {
  MuiAccordion: {
    styleOverrides: {
      root: ({ theme }) => ({
        background: theme.palette.background.default,
        padding: 0,
        margin: `${theme.spacing(3, 0)} !important`,
        '&::before': {
          opacity: 0,
        },
        '&.Mui-expanded': {
          margin: `${theme.spacing(3, 0)} !important`,
        },
      }),
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(0, 3, 3, 3),
      }),
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(3),
        minHeight: 'unset !important',
        '&.Mui-expanded': {
          margin: '0 !important',
          minHeight: 'unset !important',
        },
      }),
      expandIconWrapper: ({ theme }) => ({
        '& svg': {
          color: theme.palette.secondary.main,
        },
      }),
      content: {
        margin: `0 !important`,
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: ({ theme }) => ({
        background: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.default),
      }),
    },
    defaultProps: {
      elevation: 0,
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: ({ theme }) => ({
        borderRight: `1px solid ${theme.palette.background.default}`,

        [theme.breakpoints.down('sm')]: {
          borderRight: 'none',
        },
      }),
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: ({ theme }) => ({
        [theme.breakpoints.up('lg')]: {
          maxWidth: '1920px',
        },
      }),
    },
  },
  MuiCard: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.shape.borderRadius,
        border: `thin solid ${theme.palette.grey[200]}`,
      }),
    },
    defaultProps: {
      elevation: 0,
      variant: 'outlined',
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        transform: `translate(${theme.spacing(2)}, ${theme.spacing(2)}) scale(1)`,
        color: theme.palette.grey[500],
        '&.Mui-focused': {
          color: theme.palette.grey[500],
        },
      }),
      shrink: ({ theme }) => ({
        transform: `translate(${theme.spacing(0.5)}, -${theme.spacing(2.25)}) scale(0.75)`,
        // extends the label width and keeps the truncate effect for long labels
        width: '125%',
        maxWidth: 'unset !important',
        //
      }),
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: () => ({
        '& .MuiOutlinedInput-root': {
          padding: 0,
        },
      }),
      input: ({ theme }) => ({
        padding: `${theme.spacing(2)} !important`,
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightMedium,
        '& ~ fieldset': {
          borderColor: theme.palette.grey[400],
        },
      }),
      endAdornment: ({ theme }) => ({
        right: `${theme.spacing(2)} !important`,
        top: `calc(50% - ${theme.spacing(2.5)})`,
        display: 'flex',
        gap: theme.spacing(0.5),
      }),
    },
  },
  // <InputPhoneNumber /> requires separate style modification
  MuiOutlinedInput: {
    defaultProps: {
      // when false, the annoying blank space in the top border (to place there a label by default) is off
      notched: false,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        background: theme.palette.background.paper,
      }),
      multiline: {
        padding: '0',
      },
      input: ({ theme }) => ({
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightMedium,
        '& ~ fieldset': {
          borderColor: theme.palette.grey[400],
        },
      }),
      notchedOutline: {
        top: '-4px',
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        '& button:hover': {
          // to remove hover shadow of icon button while used in an input
          boxShadow: 'none !important',
        },
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      switchBase: ({ theme }) => ({
        color: theme.palette.secondary.main,
        '&.Mui-disabled': {
          'span[class$="-thumb"]': {
            backgroundColor: theme.palette.grey[400],
          },
          '& ~ span[class$="-track"]': {
            backgroundColor: theme.palette.grey[400],
          },
        },
      }),
      track: ({ theme }) => ({
        backgroundColor: theme.palette.grey[400],
      }),
      sizeMedium: {
        '& span[class$="-switchBase"]': {
          left: '3px',
          top: '3px',
        },
        '& span[class$="-thumb"]': {
          width: '14px',
          height: '14px',
        },
      },
      sizeSmall: {
        '& span[class$="-switchBase"]': {
          left: '3px',
          top: '3px',
        },
        '& span[class$="-thumb"]': {
          width: '10px',
          height: '10px',
        },
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(3),
      }),
    },
  },
  MuiButton: {
    defaultProps: { disableFocusRipple: true },
    styleOverrides: {
      contained: ({ theme }) => ({
        padding: theme.spacing(2),
        lineHeight: '1.125rem',
        fontSize: '0.8125rem',
        minWidth: '160px',
        boxShadow: 'none',
        transition: theme.transitions.create(['box-shadow', 'background-color', 'color'], {
          easing: theme.transitions.easing.easeInOut,
          duration: theme.transitions.duration.leavingScreen,
        }),

        '&:hover': {
          boxShadow: `0 14px 14px ${alpha(theme.palette.primary.main, 0.25)}`,
          backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.light,
        },
        '&:disabled': {
          backgroundColor: theme.palette.grey[400],
          color: theme.palette.primary.contrastText,
        },
        [theme.breakpoints.down('sm')]: {
          minWidth: '125px',
        },

        '&.MuiButton-containedError': {
          '&:hover': {
            boxShadow: `0 14px 14px ${alpha(theme.palette.error.main, 0.3)}`,
            backgroundColor: theme.palette.error.main,
          },
          '&:focus': {
            boxShadow: 'none',
            backgroundColor: theme.palette.error.light,
          },
        },
      }),
      text: ({ theme }) => ({
        color: theme.palette.primary.main,
        fontSize: '0.7858rem',

        '&:hover': {
          textDecoration: 'underline',
        },
        '&:focus': {
          color: theme.palette.primary.light,
        },
        '&:disabled': {
          color: theme.palette.grey[400],
        },
      }),
      textSecondary: {
        color: '#767E8E',
      },
      outlined: ({ theme }) => ({
        // @TODO: Secondary buttons have different styling depending on the background outside the button - how to pass the prop here to prepare the condition?
        // update: possible solution --> https://stackoverflow.com/questions/66230799/creating-custom-variants-with-material-ui
        padding: theme.spacing(2, 4),
        lineHeight: '1.125rem',
        fontSize: '0.8125rem',
        minWidth: '160px',
        background: theme.palette.background.paper,
        color: theme.palette.primary.main,
        boxShadow: theme.shadows[1],
        border: `thin solid ${theme.palette.grey[200]}`,
        transition: theme.transitions.create(['box-shadow', 'background-color', 'color'], {
          easing: theme.transitions.easing.easeInOut,
          duration: theme.transitions.duration.leavingScreen,
        }),

        '&:hover': {
          border: `thin solid ${theme.palette.grey[200]}`,
          background: theme.palette.background.paper,
          boxShadow: theme.shadows[2],
        },
        '&:focus': {
          border: `thin solid ${theme.palette.grey[200]}`,
          color: theme.palette.primary.light,
        },
        '&:disabled': {
          border: `thin solid ${theme.palette.grey[200]}`,
        },
        [theme.breakpoints.down('sm')]: {
          minWidth: '125px',
        },
      }),
      sizeSmall: ({ theme }) => ({
        padding: theme.spacing(1.5, 3),
        fontSize: '11px',
      }),
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: ({ theme }) => ({
        [theme.breakpoints.down('sm')]: {
          margin: theme.spacing(1.5),
          maxWidth: `calc(100% - ${theme.spacing(1.5 * 2)}) !important`,
          width: '100%',
          boxShadow: theme.shadows[4],
        },
      }),
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(2, 3),

        [theme.breakpoints.down('sm')]: {
          justifyContent: 'center',
        },
      }),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        paddingTop: `${theme.spacing(2.5)} !important`,
        maxHeight: 'calc(100vh - 280px)',
        [theme.breakpoints.down('sm')]: {
          maxHeight: 'calc(100vh - 240px)',
        },
      }),
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.grey[700],
      }),
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(2, 4),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[5],
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2, 2),
        },
      }),
      icon: ({ theme }) => ({
        fontSize: '28px',
        [theme.breakpoints.down('lg')]: {
          marginRight: 0,
        },
      }),
      message: {
        display: 'flex',
        alignItems: 'center',
      },
      action: {
        marginRight: 0,
        alignItems: 'center',
      },
      outlinedWarning: ({ theme }) => ({
        '& strong': {
          color: theme.palette.warning.main,
        },
      }),
      outlinedError: ({ theme }) => ({
        '& strong': {
          color: theme.palette.error.main,
        },
      }),
      outlinedSuccess: ({ theme }) => ({
        '& strong': {
          color: theme.palette.success.main,
        },
      }),
      outlinedInfo: ({ theme }) => ({
        '& strong': {
          color: theme.palette.info.main,
        },
      }),
    },
  },
  MuiAlertTitle: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.subtitle3,
      }),
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: ({ theme }) => ({
        marginLeft: theme.spacing(0.5),
        // color determined at <InputFooter /> to avoid error color overwriting
      }),
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      ...ICON_SIZES_STYLES,
    },
  },
  MuiIcon: {
    styleOverrides: {
      ...ICON_SIZES_STYLES,
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(1.5),
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.secondary.main,
        background: theme.palette.background.default,

        '& path': {
          fill: theme.palette.secondary.main,
        },
        '&:hover': {
          boxShadow: theme.shadows[2],
        },
        '&:focus': {
          color: theme.palette.primary.contrastText,
          background: theme.palette.secondary.main,
          '& path': {
            fill: theme.palette.primary.contrastText,
          },
        },
        '&:disabled': {
          color: theme.palette.grey[400],
        },
      }),
      edgeEnd: ({ theme }) => ({
        padding: theme.spacing(1),
        borderRadius: '100px',
        color: theme.palette.secondary.main,
        background: theme.palette.background.paper,

        '& path': {
          fill: theme.palette.secondary.main,
        },
        '&:focus': {
          color: theme.palette.secondary.main,
          background: theme.palette.background.paper,
          '& path': {
            fill: theme.palette.secondary.main,
          },
        },
        '&:disabled': {
          '& path': {
            fill: theme.palette.grey[400],
          },
        },
      }),
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        height: '3px',
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: ({ theme }) => ({
        alignSelf: 'flex-start',
        marginTop: theme.spacing(0.25),
      }),
    },
  },
  MuiTooltip: {
    defaultProps: {
      arrow: true,
      placement: 'right',
    },
    styleOverrides: {
      tooltip: ({ theme }) => ({
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[5],
        color: theme.palette.text.primary,
        padding: theme.spacing(3),
        borderRadius: theme.shape.borderRadius,
        border: `thin solid ${theme.palette.grey[200]}`,
      }),
      arrow: ({ theme }) => ({
        color: theme.palette.common.white,
        fontSize: '20px',
        '&:before': {
          border: `thin solid ${theme.palette.grey[200]}`,
        },
      }),
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.grey[300],
        borderRadius: theme.shape.borderRadius,
      }),
      bar: ({ theme }) => ({
        borderRadius: theme.shape.borderRadius,
      }),
    },
  },
};

export default components;
