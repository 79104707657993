import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  checkboxTextLabel: {
    marginTop: theme.spacing(0.5),
  },

  checkboxImage: {
    maxWidth: '90px',
  },
}));

export default useStyles;
