const ICON_SIZES_FOR_MAIN_MENU = {
  lg: {
    width: '22px',
    height: '22px',
  },
  sm: {
    width: '20px',
    height: '20px',
  },
};

export default ICON_SIZES_FOR_MAIN_MENU;
