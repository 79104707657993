import type { FC } from 'react';
import { memo, useMemo } from 'react';

import type { Breakpoint } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { QueryKey } from 'react-query';

import type { ParsedListData } from 'api/_parsedTypes';
import type { ListData, PaginatedApiResponse } from 'api/_types';
import Pagination from 'components/UI/organisms/_pagination/Pagination/Pagination';
import TableContainer from 'components/UI/organisms/_tables/TableContainer/TableContainer';
import TableDesktop from 'components/UI/organisms/_tables/TableDesktop/TableDesktop';
import TableMobileCard from 'components/UI/organisms/_tables/TableMobileCard/TableMobileCard';
import ElementWithLoader from 'components/UI/organisms/ElementWithLoader/ElementWithLoader';
import type { UrlQueryParams } from 'constants/_types/Params';
import type { TableHeader } from 'constants/_types/TableHeader';
import { usePagination } from 'hooks/usePagination/usePagination';

import { prepareMobileRowCustomStyles } from './_services/prepareMobileRowCustomStyles/prepareMobileRowCustomStyles';

export interface Props {
  headers: TableHeader[];
  dataSource: {
    queryKey: QueryKey;
    queryFunction: (params?: UrlQueryParams) => () => Promise<PaginatedApiResponse<ListData>>;
    additionalParams?: Record<string, boolean>;
  };
  breakpoint?: number | Breakpoint;
  dataParser: (data: PaginatedApiResponse<ListData>) => ParsedListData[];
  rowLink: ({ meta }: ParsedListData) => string;
  actionsRenderer: ({ meta, created_at }: ParsedListData, shouldShowVerticalVersion?: boolean) => JSX.Element;
}

const CustomTable: FC<Props> = ({
  headers,
  dataSource: { queryKey, queryFunction, additionalParams },
  breakpoint = 'md',
  dataParser,
  rowLink,
  actionsRenderer,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const shouldShowVerticalVersion = useMediaQuery(theme.breakpoints.down(breakpoint));

  const { status, isLoading, data, isPreviousData, page, setPage } = usePagination({
    queryKey,
    queryFunction,
    additionalParams,
  });

  const parsedData = useMemo(() => {
    if (!data) return [];
    return dataParser(data);
  }, [data, t]);

  const mobileRowCustomStyles = useMemo(() => prepareMobileRowCustomStyles(theme), []);

  const paginationProps = {
    status,
    data,
    isPreviousData,
    page,
    setPage,
  };

  const tableCommonProps = {
    headers,
    rowLink,
    actionsRenderer,
  };

  return (
    <TableContainer minHeight={400} pagination={<Pagination {...paginationProps} />}>
      <ElementWithLoader isLoading={isLoading}>
        {shouldShowVerticalVersion ? (
          <TableMobileCard rowCustomStyles={mobileRowCustomStyles} rows={parsedData} {...tableCommonProps} />
        ) : (
          <TableDesktop data={parsedData} {...tableCommonProps} />
        )}
      </ElementWithLoader>
    </TableContainer>
  );
};

export default memo(CustomTable);
