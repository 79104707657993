import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';

import { getResolvedTitleIcon } from 'components/pages/OrderServicePage/_services/getResolvedTitleIcon/getResolvedTitleIcon';
import type { DashboardButtonOrderServiceType } from 'constants/_types/DashboardButtons';
import type { OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';
import { useGetSickCardButtons } from 'hooks/_dashboardButtons/useGetSickCardButtons/useGetSickCardButtons';
import orderServiceMessages from 'translations/specific/orderService.mjs';

interface HookParams {
  locationStateType: OrderServiceOrigins | undefined;
  isSingleService: boolean;
  servicesNumber: number | undefined;
}

export const useResolvedTextAndIcon = ({ locationStateType, isSingleService, servicesNumber }: HookParams) => {
  const { t } = useTranslation();

  const text = useMemo(() => {
    if (!locationStateType || !servicesNumber) return null;

    const translationParams = {
      servicesNumber,
      serviceTypeTranslated: t(orderServiceMessages.submission.success.notification.requestType[locationStateType]),
      strongContentTranslated: t(orderServiceMessages.submission.success.notification.strongContent),
    };
    const translationWithHTML = isSingleService
      ? `${t(orderServiceMessages.submission.success.notification.singleRequest, translationParams)}`
      : `${t(orderServiceMessages.submission.success.notification.multipleRequests, translationParams)}`;

    return <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(translationWithHTML) }} />;
  }, [servicesNumber, isSingleService, locationStateType, t]);

  const { sickCardButtons } = useGetSickCardButtons() as { sickCardButtons: DashboardButtonOrderServiceType[] };
  const icon = useMemo(() => getResolvedTitleIcon(sickCardButtons, locationStateType), [locationStateType]);

  return { text, headerText: locationStateType ? t(orderServiceMessages[locationStateType].basicName) : '', icon };
};
