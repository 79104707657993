const menuMessages = {
  patientCardCaption: ['menu>>patientCardCaption', 'Pacjent'],
  loggedUser: ['menu>>loggedUser', 'Zalogowany'],
  logout: [ 'menu>>elements>>logout', 'Wyloguj' ],
  elements: {
    start: [ 'menu>>elements>>start', 'Dashboard' ],
    policies: [ 'menu>>elements>>policies', 'Polisy' ],
    my_profile: [ 'menu>>elements>>my_profile', 'Profil pacjenta' ],
    reports: [ 'menu>>elements>>reports', 'Raporty WL' ],
    settings: [ 'menu>>elements>>settings', 'Ustawienia' ],
    help: [ 'menu>>elements>>help', 'Pomoc' ],
    services: [ 'menu>>elements>>services', 'Usługi' ]
  }
}

export default menuMessages
