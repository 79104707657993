import { useCallback, useEffect, useMemo, useState } from 'react';

import type { UseFormReturn } from 'react-hook-form';

import type { ParsedPatientData } from 'api/_parsedTypes';
import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import type { PatientFormInput } from 'components/UI/organisms/PatientPersonalInfo/_constants/patientFormKeys';
import { PATIENT_FORM_INPUT_KEYS } from 'components/UI/organisms/PatientPersonalInfo/_constants/patientFormKeys';
import type { Gender } from 'constants/_types/Gender';

export interface Params {
  parsedUserData: ParsedPatientData | null;
  form: UseFormReturn<PatientFormInput, any>;
  isEditMode: boolean;
}

type UseDefaultVales = (params: Params) => { restoreInitialData: () => void };

export const useDefaultValues: UseDefaultVales = ({ parsedUserData, form, isEditMode }) => {
  const [shouldFillWithInitialData, setShouldFillWithInitialData] = useState(true);
  const { userInfo } = useAuthContext();

  const defaultValues = useMemo(
    () => ({
      [PATIENT_FORM_INPUT_KEYS.firstName]: parsedUserData?.name,
      [PATIENT_FORM_INPUT_KEYS.lastName]: parsedUserData?.surname,
      [PATIENT_FORM_INPUT_KEYS.dateOfBirth]: parsedUserData?.dateOfBirth && new Date(parsedUserData?.dateOfBirth),
      [PATIENT_FORM_INPUT_KEYS.hasForeignDocument]: parsedUserData?.hasForeignDocument,
      [PATIENT_FORM_INPUT_KEYS.documentType]: parsedUserData?.documentType,
      [PATIENT_FORM_INPUT_KEYS.documentNumber]: parsedUserData?.documentNumber,
      [PATIENT_FORM_INPUT_KEYS.documentCountry]: parsedUserData?.documentCountry,
      [PATIENT_FORM_INPUT_KEYS.pesel]: parsedUserData?.pesel,
      [PATIENT_FORM_INPUT_KEYS.gender]: parsedUserData?.gender as Gender,
      [PATIENT_FORM_INPUT_KEYS.phoneNumber]: parsedUserData?.phoneNumber || '',
      [PATIENT_FORM_INPUT_KEYS.email]: parsedUserData?.email || userInfo.email || '',
      [PATIENT_FORM_INPUT_KEYS.street]: parsedUserData?.street,
      [PATIENT_FORM_INPUT_KEYS.city]: parsedUserData?.city,
      [PATIENT_FORM_INPUT_KEYS.country]: parsedUserData?.country,
      [PATIENT_FORM_INPUT_KEYS.buildingNumber]: parsedUserData?.buildingNumber,
      [PATIENT_FORM_INPUT_KEYS.postcode]: parsedUserData?.postcode,
      [PATIENT_FORM_INPUT_KEYS.apartmentNumber]: parsedUserData?.apartmentNumber,
      clauses: {},
    }),
    [parsedUserData, userInfo],
  );

  const restoreInitialData = useCallback(() => form.reset(defaultValues), [defaultValues]);

  useEffect(() => {
    // Fill the form with initial data only once
    if (parsedUserData && shouldFillWithInitialData && userInfo) {
      restoreInitialData();
      setShouldFillWithInitialData(false);
    }
  }, [parsedUserData, userInfo]);

  useEffect(() => {
    // Restore initial data (e.g. when the form was changed, not sent, and toggled to edit mode again)
    if (isEditMode) restoreInitialData();
  }, [isEditMode]);

  return { restoreInitialData };
};
