import type { FC } from 'react';

import { Drawer, Fade, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useBrandContext } from 'components/context/BrandContext/BrandContext';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import MenuButton from 'components/UI/molecules/MenuButton/MenuButton';
import PatientCardSmall from 'components/UI/molecules/PatientCardSmall/PatientCardSmall';
import getAgeFromDOB from 'helpers/getAgeFromDOB/getAgeFromDOB';
import useMenuContent from 'hooks/useMenuContent/useMenuContent';
import useLayoutStorage from 'storages/layoutStorage';
import menuMessages from 'translations/specific/menu.mjs';

import useStyles from './MainMenuDesktop.styles';

type Props = {
  disabled?: boolean;
};
const MainMenuDesktop: FC<Props> = ({ disabled }) => {
  const { t } = useTranslation();
  const { isOpen } = useLayoutStorage();
  const { brandInfo } = useBrandContext();
  const menuContent = useMenuContent();
  const { patient } = usePatientContext();

  const { classes } = useStyles({ isOpen });
  return (
    <Drawer classes={{ paper: classes.drawerInner }} className={classes.drawer} open={isOpen} variant='permanent'>
      <div className={classes.logoContainer}>
        {brandInfo?.brand.logo_left && <img alt={brandInfo?.name} className={classes.logo} src={brandInfo.brand.logo_left} />}
      </div>
      <div className={classes.drawerOptions}>
        {patient && (
          <div>
            <Fade in={isOpen}>
              <Typography align='center' className={classes.caption} component='p' variant='caption'>
                {t(menuMessages.patientCardCaption)}:
              </Typography>
            </Fade>
            <PatientCardSmall
              age={getAgeFromDOB(patient.personal_data.date_of_birth)}
              firstName={patient.personal_data.first_name}
              imageUrl={patient.cropped_image}
              lastName={patient.personal_data.last_name}
            />
          </div>
        )}
        {menuContent.map(({ label, to, icon, isActiveFor, testId, hidden }) => (
          <MenuButton disabled={disabled} hidden={hidden} icon={icon} isActiveFor={isActiveFor} key={label} testId={testId} to={to}>
            {label}
          </MenuButton>
        ))}
      </div>
    </Drawer>
  );
};

export default MainMenuDesktop;
