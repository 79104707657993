import { useCallback, useState } from 'react';

import type { UseFormSetError, UseFormSetValue } from 'react-hook-form';
import { useMutation } from 'react-query';

import type { UpdateAddressDataInput } from 'api/addressData';
import addressData from 'api/addressData';
import type { CreatePatientsInput } from 'api/patients';
import patients from 'api/patients';
import type { UpdatePersonalDataInput } from 'api/personalData';
import personalData from 'api/personalData';
import type { PhoneUpdateInput } from 'api/users';
import users from 'api/users';
import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import type { PatientFormInput } from 'components/UI/organisms/PatientPersonalInfo/_constants/patientFormKeys';
import { PATIENT_FORM_INPUT_KEYS } from 'components/UI/organisms/PatientPersonalInfo/_constants/patientFormKeys';
import useOnBackendFormError from 'hooks/useOnBackendFormError/useOnBackendFormError';
import { useShowSnackbar } from 'hooks/useShowSnackbar/useShowSnackbar';
import snackbarMessages from 'translations/common/snackbar.mjs';

export const CREATE_PATIENT_MUTATION_KEY = 'Patient create';
export const UPDATE_PATIENT_MUTATION_KEY = 'Patient update';
export const UPDATE_PATIENT_ADDRESS_MUTATION_KEY = 'Patient address update';
export const UPDATE_PATIENT_NUMBER_MUTATION_KEY = 'Patient number update';
export const REVERT_PATIENT_NUMBER_MUTATION_KEY = 'Patient number revert';

type UsePatientMutationArguments = {
  setError: UseFormSetError<PatientFormInput>;
  setValue: UseFormSetValue<PatientFormInput>;
  onLoadingEnd: () => void;
  refreshData: (config: { action: 'create' | 'update' }) => void;
};

const usePatientMutations = ({ setError, setValue, onLoadingEnd, refreshData }: UsePatientMutationArguments) => {
  const [generalErrors, setGeneralErrors] = useState<string[]>();
  const { userInfo } = useAuthContext();
  const { patient } = usePatientContext();
  const { showSnackbar } = useShowSnackbar();

  const setGeneralErrorsWithLoadingEnd = (errors: string[]) => {
    onLoadingEnd();
    setGeneralErrors(errors);
  };
  const showSuccessSnackbar = useCallback(() => {
    showSnackbar({ variant: 'success', translationArray: snackbarMessages.success });
  }, []);

  const onErrorCreate = useOnBackendFormError<PatientFormInput, CreatePatientsInput>({
    setError,
    setGeneralErrors: setGeneralErrorsWithLoadingEnd,
  });

  const createPersonalDataMutation = useMutation(CREATE_PATIENT_MUTATION_KEY, patients.createPatient(), {
    onSuccess: () => {
      refreshData({ action: 'create' });
      showSuccessSnackbar();
    },
    onError: onErrorCreate,
    retry: false,
  });

  const onErrorUpdate = useOnBackendFormError<PatientFormInput, UpdatePersonalDataInput>({
    setError,
    setGeneralErrors: setGeneralErrorsWithLoadingEnd,
  });

  const updatePersonalDataMutation = useMutation(UPDATE_PATIENT_MUTATION_KEY, personalData.patchPersonalData(patient?.personal_data.id), {
    onSuccess: showSuccessSnackbar,
    onError: onErrorUpdate,
  });

  const onErrorUpdateAddress = useOnBackendFormError<PatientFormInput, UpdateAddressDataInput>({
    setError,
    setGeneralErrors: setGeneralErrorsWithLoadingEnd,
  });

  const updateAddressDataMutation = useMutation(
    UPDATE_PATIENT_ADDRESS_MUTATION_KEY,
    addressData.patchAddressData(patient?.personal_data.main_address.id),
    {
      // omitted onSuccess: showSuccessSnackbar to avoid doubled success snackbar with updatePersonalDataMutation
      onError: onErrorUpdateAddress,
    },
  );

  const onErrorNumberUpdate = useOnBackendFormError<PatientFormInput, PhoneUpdateInput>({
    setError,
    setGeneralErrors: setGeneralErrorsWithLoadingEnd,
  });

  const updateNumberMutation = useMutation(UPDATE_PATIENT_NUMBER_MUTATION_KEY, users.phoneUpdate(), {
    onError: onErrorNumberUpdate,
  });

  const onErrorNumberRevert = useOnBackendFormError<PatientFormInput, undefined>({
    setError,
    setGeneralErrors: setGeneralErrorsWithLoadingEnd,
  });

  const revertNumberMutation = useMutation(REVERT_PATIENT_NUMBER_MUTATION_KEY, users.phoneUpdateRevert(), {
    onSuccess: () => {
      if (userInfo[PATIENT_FORM_INPUT_KEYS.phoneNumber as 'phoneNumber'])
        setValue(PATIENT_FORM_INPUT_KEYS.phoneNumber, userInfo[PATIENT_FORM_INPUT_KEYS.phoneNumber as 'phoneNumber'] as string);
      // omitted showSuccessSnackbar to avoid doubled success snackbar with updatePersonalDataMutation
    },
    onError: onErrorNumberRevert,
  });

  return {
    createPersonalDataMutation,
    updatePersonalDataMutation,
    updateAddressDataMutation,
    updateNumberMutation,
    revertNumberMutation,
    patientMutationsGeneralError: generalErrors,
  };
};

export default usePatientMutations;
