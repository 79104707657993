import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    padding: theme.spacing(2.25, 6.25),
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2.45),
    },
  },
  youAreHere: {
    marginRight: theme.spacing(2),
    color: theme.palette.grey[500],
  },
  divider: {
    marginBottom: theme.spacing(4),
  },
}));

export default useStyles;
