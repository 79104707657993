import type { CoreServiceSubtype, CoreServiceSubtypeDTO } from 'api/coreServiceSubtypes/types';
import parseDynamicTranslatableName from 'services/parseDynamicTranslatableName/parseDynamicTranslatableName';

export const parseServiceSubtypesForFE = (serviceSubtypes: CoreServiceSubtypeDTO[]): CoreServiceSubtype[] =>
  serviceSubtypes
    .sort((a, b) => a.ordering - b.ordering)
    .map(entity => ({
      id: entity.id,
      serviceOrigin: entity.service_origin,
      name: parseDynamicTranslatableName(entity.name),
      masterType: entity.master_type,
      masterTypeName: parseDynamicTranslatableName(entity.master_type_name),
      ordering: entity.ordering,
    }));
