import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  toolbarRoot: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridGap: theme.spacing(2),
  },
  searchIcon: {
    justifySelf: 'flex-end',
  },
  element: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: theme.spacing(2),
  },
  elementRight: {
    justifyContent: 'flex-end',
  },
  serviceProvider: {
    display: 'grid',
    gridGap: theme.spacing(1),
    gridTemplateColumns: 'auto auto',
    alignItems: 'center',
    whiteSpace: 'pre-line',
  },
  logo: {
    maxWidth: '120px',
    maxHeight: '60px',
  },
}));

export default useStyles;
