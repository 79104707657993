import axios from 'axios';

import type { PaginatedApiResponse } from 'api/_types';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import { parsePatientPolicyForFE } from '../parsePatientPolicyForFE/parsePatientPolicyForFE';
import { endpoint } from '../patientPolicies';
import type { PatientPolicy, PatientPolicyDTO } from '../patientPolicies.types';

type GetPoliciesForPatientQuery = () => Promise<PatientPolicy[]>;

export const getPoliciesForPatient =
  (patientId: number): GetPoliciesForPatientQuery =>
  async () => {
    if (!patientId) throw Error('Missing patient ID!');
    const { data } = await axios.get<GetPoliciesForPatientQuery, PaginatedApiResponse<PatientPolicyDTO>>(
      createUrlWithParams({ url: endpoint, params: { patient: patientId } }),
    );

    return parsePatientPolicyForFE(data.results);
  };
