import type { FC } from 'react';

import { FormControl, Autocomplete, AutocompleteValue, TextField } from '@mui/material';

import InputFooter from 'components/UI/atoms/InputFooter/InputFooter';
import type { DropdownOption } from 'constants/_types/DropdownOptions';

import useStyles from './InputAutocomplete.styles';

export type Props = {
  label: string;
  helperText?: string;
  error?: string | boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  freeSolo?: boolean;
  options: DropdownOption[] | [];
  value?: string;
  onChange: (value: AutocompleteValue<any, any, any, any> | string | undefined) => void;
  setSelectedItemMetaData?: (metaData: Pick<DropdownOption, 'meta'>) => void;
};

const InputAutocomplete: FC<Props> = ({ label, helperText, error, fullWidth = true, options, disabled, freeSolo, ...field }) => {
  const { value, setSelectedItemMetaData, onChange, ...rest } = field;

  const { classes } = useStyles();

  return (
    <FormControl classes={{ root: classes.control }} disabled={disabled} error={!!error} fullWidth={fullWidth} variant='outlined'>
      <Autocomplete
        disabled={disabled}
        freeSolo={freeSolo}
        getOptionLabel={option => (typeof option === 'string' ? option : option.label)}
        inputValue={value || ''}
        options={options.map(option => option)}
        renderInput={params => <TextField {...params} disabled={disabled} error={!!error} label={label} />}
        onChange={(e, _value) => {
          if (typeof _value !== 'string' && setSelectedItemMetaData) setSelectedItemMetaData(_value?.meta ?? { '': '' });
          onChange(_value);
        }}
        onInputChange={(e, _value) => onChange(_value)}
        {...rest}
        value={value || ''}
      />
      <InputFooter error={error} helperText={helperText} />
    </FormControl>
  );
};

export default InputAutocomplete;
