import { useEffect } from 'react';

import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';

import AuthContextProvider from 'components/context/AuthContext/AuthContext';
import ClauseLocationsContextProvider from 'components/context/ClauseLocationsContext/ClauseLocationsContext';
import ConfirmationDialogProvider from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import GlobalDialogsContextProvider from 'components/context/GlobalDialogsContext/GlobalDialogsContext';
import PatientContextProvider from 'components/context/PatientContext/PatientContext';
import Router from 'components/root/Router/Router';
import { APP_EVENTS } from 'constants/_types/AppEvents';
import { SupportedLanguages } from 'constants/translations/_types';
import locales from 'constants/translations/locales';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';
import { useShowSnackbar } from 'hooks/useShowSnackbar/useShowSnackbar';
import useSubscription from 'hooks/useSubscriptions/useSubscription';
import snackbarMessages from 'translations/common/snackbar.mjs';

const App = () => {
  const langCode = useLanguageCode();
  const { showSnackbar } = useShowSnackbar();
  const callback = () => {
    showSnackbar({ variant: 'error', translationArray: snackbarMessages.failureDetailed, hasRefreshButton: true });
  };
  useSubscription(APP_EVENTS.apiMutationError, callback);
  useSubscription(APP_EVENTS.apiFetchError, callback);

  useEffect(() => {
    console.log('%cUwaga!', 'color:red; font-size: 80px; font-weight: 800');
    console.log(
      '%cTa funkcja przeglądarki jest przeznaczona dla programistów. Jeżeli ktoś polecił Ci wykonać jakieś akcje tutaj prawodpodobnie próbuje ukraść twoje dane.',
      'font-size: 24px',
    );
  }, []);

  return (
    <GoogleReCaptchaProvider language={langCode} reCaptchaKey={process.env.REACT_APP_RECAPTCHA_PUBLIC}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locales[langCode as SupportedLanguages]}>
        <AuthContextProvider>
          <PatientContextProvider>
            <ConfirmationDialogProvider>
              <ClauseLocationsContextProvider>
                <GlobalDialogsContextProvider>
                  <BrowserRouter>
                    <Router />
                  </BrowserRouter>
                </GlobalDialogsContextProvider>
              </ClauseLocationsContextProvider>
            </ConfirmationDialogProvider>
          </PatientContextProvider>
        </AuthContextProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </LocalizationProvider>
    </GoogleReCaptchaProvider>
  );
};

export default App;
