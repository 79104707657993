import type { FC } from 'react';
import { useMemo, useState } from 'react';

import type { UseFormReturn } from 'react-hook-form';

import type { OrderServiceFormInput } from 'components/context/OrderServiceFormContext/_constants/inputKeys';
import FormInputFileHidden from 'components/UI/organisms/_formInputs/FormInputFileHidden/FormInputFileHidden';
import { ACCEPTED_FILE_FORMATS, ACCEPTED_FILE_FORMATS_REGEX } from 'constants/_files/acceptedFileFormats';
import { useProcessUploadedFile } from 'hooks/_fileProcessing/useProcessUploadedFile/useProcessUploadedFile';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import AddReferralButton from './_components/AddReferralButton/AddReferralButton';
import UploadedReferral from './_components/UploadedReferral/UploadedReferral';
import { FILE_DEFAULT_VALUE } from './_constants/fileDefaultValue';
import { REFERRAL_STATES, ReferralState } from './_constants/referralState';
import { useFileHandlers } from './_hooks/useFileHandlers/useFileHandlers';
import useStyles from './ReferralAttachment.styles';

export interface Props {
  form: UseFormReturn<OrderServiceFormInput, any>;
  formInputName: string;
  validationRules: { validate: () => string | true };
}

const ReferralAttachment: FC<Props> = ({ form, formInputName, validationRules }) => {
  const [attachmentState, setAttachmentState] = useState<ReferralState>('processing');
  const { classes } = useStyles();

  const { filename, inputRef, handleAdd, handleFileProceed, handleRemove } = useFileHandlers({
    form,
    formFileName: formInputName,
    setAttachmentState,
  });

  const fileProcessingConfig = useMemo(
    () => ({
      proceedingCallback: handleFileProceed,
      config: {
        fileType: {
          acceptedType: ACCEPTED_FILE_FORMATS_REGEX,
          errorMessageTranslationArray: orderServiceMessages.details.file.incorrectExtension,
        },
        fileSize: { errorMessageTranslationArray: orderServiceMessages.details.file.tooBig },
      },
    }),
    [],
  );

  const handleOnUploadedFile = useProcessUploadedFile(fileProcessingConfig);

  const content = useMemo(() => {
    // prepared for more states in the future - just in case
    switch (attachmentState) {
      case REFERRAL_STATES.loaded:
        return <UploadedReferral filename={filename} handleRemove={handleRemove} />;
      case REFERRAL_STATES.processing:
        return <AddReferralButton isLoading />;
      case REFERRAL_STATES.initial:
      default:
        return <AddReferralButton handleAdd={handleAdd} />;
    }
  }, [attachmentState]);

  return (
    <div className={classes.referral}>
      {content}
      <FormInputFileHidden
        acceptedFormats={ACCEPTED_FILE_FORMATS}
        control={form.control}
        defaultValue={FILE_DEFAULT_VALUE}
        inputRef={inputRef}
        name={formInputName as any}
        rules={validationRules}
        onUploadedFile={handleOnUploadedFile}
      />
    </div>
  );
};

export default ReferralAttachment;
