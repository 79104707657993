import { ParsedPatientData } from 'api/_parsedTypes';
import type { Patient } from 'api/_types';
import type { UserInfo } from 'components/context/AuthContext/AuthContext';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import { parseAddress } from 'services/parseAddress/parseAddress';

export const userDataParser = (dataToParse: Patient, userInfo: UserInfo): ParsedPatientData => ({
  name: dataToParse.personal_data.first_name,
  surname: dataToParse.personal_data.last_name,
  email: dataToParse.personal_data.email,
  gender: dataToParse.personal_data.gender,
  pesel: dataToParse.pesel,
  hasForeignDocument: dataToParse.foreign_document,
  documentType: dataToParse.foreign_document_type,
  documentNumber: dataToParse.foreign_document_number,
  documentCountry: dataToParse.foreign_document_country,
  dateOfBirth: unknownDateToString(dataToParse.personal_data.date_of_birth),
  phoneNumber: userInfo?.phoneNumber || '',
  imageUrl: dataToParse.cropped_image,
  ...parseAddress(dataToParse.personal_data.main_address),
});
