import type { PersonalDataUnassigned } from 'api/_types';
import { GENDER } from 'constants/_types/Gender';
import { GENDER_TITLES } from 'constants/_types/GenderTitle';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';

import type { PatientFormInput } from '../../_constants/patientFormKeys';
import { PATIENT_FORM_INPUT_KEYS } from '../../_constants/patientFormKeys';

export const preparePersonalDataForUpdate = (rawData: PatientFormInput) => {
  const result: PersonalDataUnassigned = {
    title: rawData[PATIENT_FORM_INPUT_KEYS.gender as 'gender'] === GENDER.m ? GENDER_TITLES.mr : GENDER_TITLES.mrs,
    first_name: rawData[PATIENT_FORM_INPUT_KEYS.firstName as 'firstName'],
    last_name: rawData[PATIENT_FORM_INPUT_KEYS.lastName as 'lastName'],
    email: rawData[PATIENT_FORM_INPUT_KEYS.email as 'email'],
    phone_number: rawData[PATIENT_FORM_INPUT_KEYS.phoneNumber as 'phoneNumber'],
    gender: rawData[PATIENT_FORM_INPUT_KEYS.gender as 'gender'],
  };
  if (rawData.hasForeignDocument) {
    result.date_of_birth = unknownDateToString(
      new Date(rawData[PATIENT_FORM_INPUT_KEYS.dateOfBirth as 'dateOfBirth']),
      DATE_FORMATS.API_DATE,
    );
  }

  return result;
};
