import type { CSSObject } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

type StylesProps = {
  cellSize?: number;
  isSelected: boolean;
  isMatched?: boolean;
  isSearchActive?: boolean;
};

const elements = ['tile', 'label', 'button'] as const;
type Elements = typeof elements[number];

const useStyles = makeStyles<StylesProps>()((theme, { cellSize, isSelected, isMatched, isSearchActive }) => {
  const stylesForSelected: Record<Elements, CSSObject> = {
    tile: {},
    label: {
      color: theme.palette.primary.main,
    },
    button: {
      opacity: 1,
    },
  };

  const stylesForMatched: Record<Elements, CSSObject> = {
    tile: {
      background: theme.palette.colorBackground.primary.main,
      boxShadow: theme.shadows[2],
    },
    label: {
      color: theme.palette.primary.main,
    },
    button: {},
  };

  const stylesForDisabled: Record<Elements, CSSObject> = {
    tile: {
      opacity: 0.3,
      pointerEvents: 'none',
    },
    label: {},
    button: {},
  };

  const stylesForHovered: Record<Elements, CSSObject> = {
    tile: {
      background: theme.palette.colorBackground.primary.main,
      boxShadow: theme.shadows[2],
    },
    label: {
      color: theme.palette.primary.main,
    },
    button: {
      opacity: 1,
    },
  };

  return {
    cellTile: {
      padding: theme.spacing(1, 0.75),
      width: cellSize ? `${cellSize}px` : '100%',
      display: 'grid',
      wordBreak: 'break-word',
      gridTemplateColumns: `1fr 19px`,
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: theme.spacing(0.5),
      transition: theme.transitions.create(['box-shadow', 'background', 'opacity'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
      ...(isSearchActive && !isMatched && stylesForDisabled.tile),
      ...(isSelected && stylesForSelected.tile),
      ...(isMatched && stylesForMatched.tile),

      '&:hover ': {
        ...stylesForHovered.tile,

        '& span': {
          ...stylesForHovered.label,
        },

        '& button': {
          ...stylesForHovered.button,
        },
      },
    },
    label: {
      transition: theme.transitions.create(['color'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
      textAlign: 'left',
      ...(isSelected && stylesForSelected.label),
      ...(isMatched && stylesForMatched.label),
    },
    actionButton: {
      transition: theme.transitions.create(['opacity'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
      opacity: 0,
      ...(isSelected && stylesForSelected.button),
    },
  };
});

export default useStyles;
