import { useMemo, type FC } from 'react';

import { Divider, Typography } from '@mui/material';

import CloseButton from 'components/UI/atoms/CloseButton/CloseButton';
import type { OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';

import PageTitleContent from './_components/PageTitleContent/PageTitleContent';
import PageTitleStepperContent from './_components/PageTitleStepperContent/PageTitleStepperContent';
import useStyles from './PageTitle.styles';

export interface Props {
  icon: JSX.Element | null;
  headerLabel: string;
  extraContentComponent?: JSX.Element;
  buttonLabel?: string;
  buttonDisabled?: boolean;
  redirectPath?: string;
  formMaxStep?: number;
  formStep?: number;
  hasCloseButton?: boolean;
  paramOrigin?: OrderServiceOrigins;
}

const PageTitle: FC<Props> = ({
  icon,
  headerLabel,
  buttonLabel,
  extraContentComponent,
  formMaxStep,
  formStep,
  redirectPath,
  buttonDisabled,
  hasCloseButton = false,
  paramOrigin,
}) => {
  const hasStepper = useMemo(() => !!formMaxStep && !!formStep, [formMaxStep, formStep]);

  const { classes } = useStyles({ hasStepper });

  return (
    <div className={classes.root}>
      <div className={classes.textContainer}>
        <div className={classes.headerContainer}>
          {icon && <div className={classes.iconContainer}>{icon}</div>}
          <Typography className={classes.title} component='h1' variant='h2'>
            {headerLabel}
          </Typography>
        </div>
        {hasCloseButton && <CloseButton />}
      </div>

      {hasStepper && paramOrigin ? (
        <>
          <Divider className={classes.divider} variant='fullWidth' />
          <div className={classes.stepper}>
            <PageTitleStepperContent formMaxStep={formMaxStep as number} formStep={formStep as number} paramOrigin={paramOrigin} />
          </div>
        </>
      ) : (
        <PageTitleContent
          buttonDisabled={buttonDisabled}
          buttonLabel={buttonLabel}
          dividerComponent={<Divider className={classes.divider} variant='fullWidth' />}
          extraContentComponent={extraContentComponent}
          redirectPath={redirectPath}
        />
      )}
    </div>
  );
};

export default PageTitle;
