import type { TFunction } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';

import clausesMessages from 'translations/specific/clauses.mjs';

type Config = {
  url?: string;
  t?: TFunction;
  hideLink?: boolean;
  isObligatory?: boolean;
};

const getLabelForClause = (label: string, config: Config = {}): JSX.Element => {
  const baseModification = (labelToModify: string) => {
    // sanitize and remove trailing <br> tags
    const sanitized = sanitizeHtml(labelToModify).replace(/\s*(?:<br\s*\/?>)+\s*$/, '');
    return config.isObligatory ? `${sanitized}&nbsp;*` : sanitized;
  };

  const defaultLabel = <span dangerouslySetInnerHTML={{ __html: baseModification(label) }} />;
  const matchesTextInSquareBrackets = label.match(/\[(.*)]/);

  if (config.hideLink) {
    if (matchesTextInSquareBrackets) {
      const [textToReplace] = matchesTextInSquareBrackets;
      // --> Label (without a text in square brackets)
      return <span dangerouslySetInnerHTML={{ __html: baseModification(label.replace(textToReplace, '')) }} />;
    }

    // --> Label
    return defaultLabel;
  }

  if (config.url && matchesTextInSquareBrackets) {
    const [textToReplace, linkLabel] = matchesTextInSquareBrackets;
    const link = `&nbsp;<a href="${config.url}" target="_blank">${linkLabel}</a>`;
    // --> Label <a>custom text link</a>
    return <span dangerouslySetInnerHTML={{ __html: baseModification(label.replace(textToReplace, link)) }} />;
  }

  if (config.url && config.t) {
    const labelWithLink = `${label}&nbsp;<a href="${config.url}" rel="noreferrer" target="_blank">${config.t(
      clausesMessages.fileLink,
    )}</a>`;
    // --> Label <a>default text link</a>
    return <span dangerouslySetInnerHTML={{ __html: baseModification(labelWithLink) }} />;
  }

  // --> Label
  return defaultLabel;
};

export default getLabelForClause;
