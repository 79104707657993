import type { FC } from 'react';

import { NavigateNext } from '@mui/icons-material';
import { Breadcrumbs, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import breadcrumbsMessages from 'translations/common/breadcrumbs.mjs';

import useStyles from './BreadcrumbsSection.styles';

export interface Props {
  breadcrumbs: JSX.Element[];
}

const BreadcrumbsSection: FC<Props> = ({ breadcrumbs }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Typography className={classes.youAreHere} variant='caption'>{`${t(breadcrumbsMessages.youAreHere)}:`}</Typography>
        <Stack spacing={2}>
          <Breadcrumbs aria-label='breadcrumb' separator={<NavigateNext fontSize='small' />}>
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
      </div>
      <Divider className={classes.divider} variant='fullWidth' />
    </>
  );
};

export default BreadcrumbsSection;
