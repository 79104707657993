import { makeStyles } from 'tss-react/mui';

import AVATAR_SIZE from 'constants/styles/AVATAR_SIZE';

import { PATIENT_FORM_INPUT_KEYS } from './_constants/patientFormKeys';

const PERSONAL_SECTION = 'personalSection';
const CONTACT_SECTION = 'contactSection';
const ADDRESS_SECTION = 'addressSection';
const EDIT_BUTTON = 'editButton';
const AVATAR = 'avatar';
const FORM = 'form';
const CONSENTS = 'consents';

const useStyles = makeStyles<{ isEditMode: boolean }>()((theme, { isEditMode }) => ({
  wrapper: {
    display: 'grid',
    gridGap: theme.spacing(5),
    alignItems: 'flex-start',
    gridTemplateColumns: isEditMode ? `${AVATAR_SIZE.width}px auto` : `${AVATAR_SIZE.width}px auto 160px`,
    gridTemplateAreas: isEditMode ? `"${AVATAR} ${FORM}"` : `"${AVATAR} ${FORM} ${EDIT_BUTTON}"`,

    [theme.breakpoints.down('xxl')]: {
      gridGap: theme.spacing(3),
    },

    '@media screen and (max-width: 1400px)': {
      gridTemplateColumns: isEditMode ? `${AVATAR_SIZE.width}px auto` : `${AVATAR_SIZE.width}px 160px`,
      width: '100%',
      justifyContent: 'space-between',

      gridTemplateAreas: isEditMode
        ? `
      "${AVATAR} ."
      "${FORM} ${FORM}"
      `
        : `
      "${AVATAR} ${EDIT_BUTTON}"
      "${FORM} ${FORM}"
      `,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: isEditMode ? `${AVATAR_SIZE.width}px auto` : `${AVATAR_SIZE.width}px 125px`,
      gridGap: theme.spacing(1),
    },
  },
  avatarContainer: {
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    gridArea: AVATAR,
    '& > div': {
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      '& > div': {
        display: 'flex',
        justifyContent: isEditMode ? 'center' : 'flex-start',
      },
    },
  },
  formContainer: {
    display: 'grid',
    gridGap: theme.spacing(4),
    width: '100%',
    justifyContent: 'space-evenly',
    gridTemplateColumns: isEditMode ? '200px 200px 440px' : '200px 200px 320px',
    alignItems: 'flex-start',
    gridArea: FORM,
    gridTemplateAreas: `"${PERSONAL_SECTION} ${CONTACT_SECTION} ${ADDRESS_SECTION}"`,

    [theme.breakpoints.down('xxl')]: {
      gridGap: theme.spacing(3),
      gridTemplateColumns: isEditMode ? '210px 210px 320px' : '180px 180px 320px',
    },

    '@media screen and (max-width: 1400px)': {
      gridGap: theme.spacing(8, 3),
      gridTemplateColumns: '1fr 1fr 1fr',
      gridTemplateAreas: isEditMode
        ? `
        "${PERSONAL_SECTION} ${CONTACT_SECTION} ${ADDRESS_SECTION}"
        "${CONSENTS} ${CONSENTS} ${CONSENTS} ${CONSENTS}"
        `
        : `"${PERSONAL_SECTION} ${CONTACT_SECTION} ${ADDRESS_SECTION}"`,
    },
    '@media screen and (max-width: 900px)': {
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `
      "${PERSONAL_SECTION}"
      "${CONTACT_SECTION}"
      "${ADDRESS_SECTION}"
      "${CONSENTS}"
      `,
    },
  },
  standardColumnWrapper: {
    display: 'grid',
    gridGap: theme.spacing(3, 1),
    alignContent: 'flex-start',

    '@media screen and (max-width: 900px)': {
      gridTemplateColumns: isEditMode ? 'auto' : '1fr 1fr',
    },
  },
  fourColumnsContainer: {
    display: 'grid',
    gridGap: theme.spacing(3, 2),
    alignContent: 'flex-start',
    gridTemplateColumns: isEditMode ? 'repeat(4, 1fr)' : 'repeat(2, 1fr)',

    gridTemplateAreas: isEditMode
      ? `
    "${PATIENT_FORM_INPUT_KEYS.country as string} ${PATIENT_FORM_INPUT_KEYS.country as string} . ."

    "${PATIENT_FORM_INPUT_KEYS.postcode as string} ${PATIENT_FORM_INPUT_KEYS.city as string} ${PATIENT_FORM_INPUT_KEYS.city as string} ."
    
    "${PATIENT_FORM_INPUT_KEYS.street as string} ${PATIENT_FORM_INPUT_KEYS.street as string} ${
          PATIENT_FORM_INPUT_KEYS.buildingNumber as string
        } ${PATIENT_FORM_INPUT_KEYS.apartmentNumber as string}"
    `
      : `
    "${PATIENT_FORM_INPUT_KEYS.country as string} ${PATIENT_FORM_INPUT_KEYS.country as string} . ."

    "${PATIENT_FORM_INPUT_KEYS.city as string} ${PATIENT_FORM_INPUT_KEYS.city as string} ${PATIENT_FORM_INPUT_KEYS.postcode as string} ${
          PATIENT_FORM_INPUT_KEYS.postcode as string
        }"
    
    "${PATIENT_FORM_INPUT_KEYS.street as string} ${PATIENT_FORM_INPUT_KEYS.street as string} ${
          PATIENT_FORM_INPUT_KEYS.buildingNumber as string
        } ${PATIENT_FORM_INPUT_KEYS.apartmentNumber as string}"
    `,

    [theme.breakpoints.down('xxl')]: {
      gridTemplateColumns: isEditMode ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)',

      gridTemplateAreas: isEditMode
        ? `
        "${PATIENT_FORM_INPUT_KEYS.country as string} ${PATIENT_FORM_INPUT_KEYS.country as string} ."
        
        "${PATIENT_FORM_INPUT_KEYS.postcode as string} ${PATIENT_FORM_INPUT_KEYS.city as string} ${PATIENT_FORM_INPUT_KEYS.city as string}"
        
        "${PATIENT_FORM_INPUT_KEYS.street as string} ${PATIENT_FORM_INPUT_KEYS.street as string} ${
            PATIENT_FORM_INPUT_KEYS.street as string
          }"
        
        "${PATIENT_FORM_INPUT_KEYS.buildingNumber as string} ${PATIENT_FORM_INPUT_KEYS.apartmentNumber as string} ."
        `
        : `
        "${PATIENT_FORM_INPUT_KEYS.country as string} ${PATIENT_FORM_INPUT_KEYS.country as string} ${
            PATIENT_FORM_INPUT_KEYS.country as string
          } ${PATIENT_FORM_INPUT_KEYS.country as string}"
        
        "${PATIENT_FORM_INPUT_KEYS.city as string} ${PATIENT_FORM_INPUT_KEYS.city as string} ${
            PATIENT_FORM_INPUT_KEYS.postcode as string
          } ${PATIENT_FORM_INPUT_KEYS.postcode as string}"
        
        "${PATIENT_FORM_INPUT_KEYS.street as string} ${PATIENT_FORM_INPUT_KEYS.street as string} ${
            PATIENT_FORM_INPUT_KEYS.street as string
          } ${PATIENT_FORM_INPUT_KEYS.street as string}"
        
        "${PATIENT_FORM_INPUT_KEYS.buildingNumber as string} ${PATIENT_FORM_INPUT_KEYS.buildingNumber as string} ${
            PATIENT_FORM_INPUT_KEYS.apartmentNumber as string
          } ${PATIENT_FORM_INPUT_KEYS.apartmentNumber as string}"
        `,
    },

    '@media screen and (max-width: 700px)': {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: isEditMode
        ? `
      "${PATIENT_FORM_INPUT_KEYS.country as string} ${PATIENT_FORM_INPUT_KEYS.country as string}"
      "${PATIENT_FORM_INPUT_KEYS.city as string} ${PATIENT_FORM_INPUT_KEYS.postcode as string}"
      "${PATIENT_FORM_INPUT_KEYS.street as string} ${PATIENT_FORM_INPUT_KEYS.street as string}"
      "${PATIENT_FORM_INPUT_KEYS.buildingNumber as string} ${PATIENT_FORM_INPUT_KEYS.apartmentNumber as string}"
      `
        : `
      "${PATIENT_FORM_INPUT_KEYS.country as string} ${PATIENT_FORM_INPUT_KEYS.country as string}"
      "${PATIENT_FORM_INPUT_KEYS.city as string} ${PATIENT_FORM_INPUT_KEYS.postcode as string}"
      "${PATIENT_FORM_INPUT_KEYS.street as string} ${PATIENT_FORM_INPUT_KEYS.street as string}"
      "${PATIENT_FORM_INPUT_KEYS.buildingNumber as string} ${PATIENT_FORM_INPUT_KEYS.apartmentNumber as string}"
      `,
    },
  },
  clausesContainer: {
    gridColumn: '1 / 4',
  },
  personalSection: {
    gridArea: PERSONAL_SECTION,
  },
  contactSection: {
    gridArea: CONTACT_SECTION,
  },
  addressSection: {
    gridArea: ADDRESS_SECTION,
  },
  editButton: {
    gridArea: EDIT_BUTTON,
  },
}));

export default useStyles;
