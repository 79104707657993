import type { FunctionComponent } from 'react';

import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { ReactComponent as ReportsIcon } from 'assets/icons/hub/active/document.svg';
import { ReactComponent as ServicesIcon } from 'assets/icons/hub/active/files.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/hub/active/gearwheel.svg';
import { ReactComponent as HelpIcon } from 'assets/icons/hub/active/lifebuoy.svg';
import { ReactComponent as DashboardIcon } from 'assets/icons/hub/active/screen.svg';
import { ReactComponent as PatientProfileIcon } from 'assets/icons/hub/active/user_plus.svg';
import PATHS from 'constants/router/PATHS';
import menuMessages from 'translations/specific/menu.mjs';

import { useResolvedActivePaths } from './_hooks/useResolvedActivePaths/useResolvedActivePaths';

export type MenuEntity = {
  label: string;
  icon: FunctionComponent;
  action?: () => void;
  to: string;
  isActiveFor: string[];
  testId?: string;
  hidden?: boolean;
};

const useMenuContent = (): MenuEntity[] => {
  const { t } = useTranslation();

  const { dashboardIsActiveForPaths, servicesIsActiveForPaths } = useResolvedActivePaths();

  return [
    {
      label: t(menuMessages.elements.start),
      to: PATHS.ROOT,
      icon: DashboardIcon,
      isActiveFor: dashboardIsActiveForPaths,
    },
    {
      label: t(menuMessages.elements.services),
      to: generatePath(PATHS.SERVICES),
      icon: ServicesIcon,
      isActiveFor: servicesIsActiveForPaths,
    },
    {
      label: t(menuMessages.elements.my_profile),
      to: generatePath(PATHS.MY_PROFILE),
      icon: PatientProfileIcon,
      isActiveFor: [PATHS.MY_PROFILE],
    },
    {
      label: t(menuMessages.elements.reports),
      to: generatePath(PATHS.REPORTS),
      icon: ReportsIcon,
      isActiveFor: [PATHS.REPORTS, PATHS.REPORT_SINGLE],
    },
    { label: t(menuMessages.elements.settings), to: generatePath(PATHS.SETTINGS), icon: SettingsIcon, isActiveFor: [PATHS.SETTINGS] },
    { label: t(menuMessages.elements.help), to: generatePath(PATHS.HELP), icon: HelpIcon, isActiveFor: [PATHS.HELP] },
  ];
};

export default useMenuContent;
