import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  icon: {
    display: 'flex',
    color: theme.palette.grey[500],
  },
}));

export default useStyles;
