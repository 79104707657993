import type { FC } from 'react';
import { useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, Grid } from '@mui/material';
import { isNil } from 'lodash';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import type { RegisterQueryInput } from 'api/users';
import PasswordValidation from 'components/UI/molecules/PasswordValidation/PasswordValidation';
import SimpleFormWrapper from 'components/UI/molecules/SimpleFormWrapper/SimpleFormWrapper';
import FormInputCheckbox from 'components/UI/organisms/_formInputs/FormInputCheckbox/FormInputCheckbox';
import FormInputPassword from 'components/UI/organisms/_formInputs/FormInputPassword/FormInputPassword';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import REMEMBERED_PATH from 'constants/_queryParams/REMEMBERED_PATH';
import { CLAUSE_LOCATIONS } from 'constants/_types/ClauseLocations';
import { ClauseWithConsent } from 'constants/_types/ClauseWithConsent';
import useClausesConsentsQuery from 'hooks/_clauses/useClausesConsentsQuery/useClausesConsentsQuery';
import useRegisterMutation from 'hooks/_mutations/useRegisterMutation/useRegisterMutation';
import getLabelForClause from 'services/_clauses/getLabelForClause/getLabelForClause';
import authMessages from 'translations/common/auth.mjs';
import validationMessages from 'translations/common/validation.mjs';
import registerMessages from 'translations/specific/register.mjs';

import useStyles from './RegisterForm.styles';

export type RegisterFormInput = {
  email: string;
  password: string;
  re_password: string;
  consents: {
    [key: string]: boolean;
  };
};

const parseData = (
  data: RegisterFormInput,
  config: {
    clauses: ClauseWithConsent[];
    location: number;
  },
  redirectUrl?: string,
): Omit<RegisterQueryInput, 'recaptcha'> => {
  const { clauses, location } = config;

  const consents: {
    clause: number;
    ordering: number | null;
    location: number;
    declined: boolean;
  }[] = clauses.map(({ clauseId, slot }) => {
    const hasConsent = data.consents[clauseId] || false;

    return {
      clause: Number(clauseId),
      ordering: slot,
      location,
      declined: !hasConsent,
    };
  });

  return {
    email: data.email,
    password: data.password,
    re_password: data.re_password,
    redirect_url: redirectUrl,
    consents,
  };
};

const RegisterForm: FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [passwordValid, setPasswordValid] = useState<boolean>(false);
  const { clausesWithConsents: clauses, locationsMapping } = useClausesConsentsQuery([CLAUSE_LOCATIONS.register], {
    shouldGetConsents: false,
  });

  const { control, handleSubmit, watch, getValues, setError, formState } = useForm<RegisterFormInput>({ defaultValues: { consents: {} } });
  watch();

  const { registerMutate, isRegisterMutating, registerMutateGeneralErrors } = useRegisterMutation({ setError });

  const onSubmit: SubmitHandler<RegisterFormInput> = async data => {
    const location = locationsMapping[CLAUSE_LOCATIONS.register];
    if (!clauses || isNil(location)) {
      throw Error('Invalid clauses config');
    }
    const redirectUrl = searchParams.get(REMEMBERED_PATH) || undefined;

    registerMutate(parseData(data, { clauses, location }, redirectUrl));
  };

  const { classes } = useStyles();
  return (
    <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
      <Typography align='center' component='h1' marginBottom={2} variant='h3'>
        {t(registerMessages.header)}
      </Typography>
      <SimpleFormWrapper formState={formState} globalErrors={registerMutateGeneralErrors}>
        <FormInputText
          control={control}
          label={t(authMessages.email)}
          name='email'
          required
          rules={{ pattern: { value: /@/, message: t(validationMessages.invalid_email) } }}
        />
        <FormInputPassword control={control} label={t(authMessages.new_password)} name='password' required />
        <FormInputPassword control={control} label={t(authMessages.repeat_new_password)} name='re_password' required />
        <PasswordValidation password={getValues().password} rePassword={getValues().re_password} setIsValid={setPasswordValid} />
        <Grid container data-testid='terms_container'>
          {clauses &&
            clauses.map(({ clauseId, isObligatory, text, file }) => (
              <Grid item xs={12}>
                <FormInputCheckbox
                  control={control}
                  hideAsterisk
                  label={getLabelForClause(text, { url: file, t, isObligatory })}
                  name={`consents.${clauseId}`}
                  required={isObligatory}
                  tiny
                />
              </Grid>
            ))}
        </Grid>
        <LoadingButton
          data-testid='register_button'
          disabled={!passwordValid}
          loading={isRegisterMutating}
          type='submit'
          variant='contained'
        >
          {t(registerMessages.button_label)}
        </LoadingButton>
      </SimpleFormWrapper>
    </form>
  );
};

export default RegisterForm;
