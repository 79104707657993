import create from 'zustand';

type MyProfileUIStorage = {
  isPatientEditorOpen: boolean;
  openPatientEditor: () => void;
  closePatientEditor: () => void;
};

const useMyProfileUIStorage = create<MyProfileUIStorage>(set => ({
  isPatientEditorOpen: false,
  openPatientEditor: () => set({ isPatientEditorOpen: true }),
  closePatientEditor: () => set({ isPatientEditorOpen: false }),
}));

export default useMyProfileUIStorage;
