import type { FC } from 'react';

import { Card, Divider, Tab, Tabs } from '@mui/material';

import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import DisabledWrapper from 'components/UI/atoms/DisabledWrapper/DisabledWrapper';
import PatientClauses from 'components/UI/organisms/PatientClauses/PatientClauses';
import PatientPersonalInfo from 'components/UI/organisms/PatientPersonalInfo/PatientPersonalInfo';
import PatientPolicyInfo from 'components/UI/organisms/PatientPolicyInfo/PatientPolicyInfo';
import useMyProfileUIStorage from 'storages/myProfileUIStorage';

import useStyles from './PatientCard.styles';

const PatientCard: FC = () => {
  const { isPatientEditorOpen } = useMyProfileUIStorage();

  const { classes } = useStyles();
  const { patient } = usePatientContext();

  return (
    <Card>
      <div className={classes.tabsContainer}>
        <Tabs indicatorColor='secondary' textColor='secondary' value={0}>
          {!!patient && <Tab component='h2' label={`${patient.personal_data.first_name} ${patient.personal_data.last_name}`} />}
        </Tabs>
      </div>
      <Divider variant='fullWidth' />
      <PatientPersonalInfo />
      <DisabledWrapper disabled={isPatientEditorOpen}>
        <PatientPolicyInfo />
        <PatientClauses />
      </DisabledWrapper>
    </Card>
  );
};

export default PatientCard;
