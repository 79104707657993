import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import AddItemButton from 'components/UI/atoms/AddItemButton/AddItemButton';
import RemoveItemButton from 'components/UI/atoms/RemoveItemButton/RemoveItemButton';
import type { CellRendererProps } from 'components/UI/molecules/ColumnsDisplaySet/_components/ColumnsDisplaySetRows/ColumnsDisplaySetRows';
import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';

import TruncatedTextWithTooltip from '../../atoms/TruncatedTextWithTooltip/TruncatedTextWithTooltip';
import useStyles from './BaseColumnCellRenderer.styles';

export const testId = createTestIdObject('BaseColumnCellRenderer', {
  removeButton: 'removeButton',
  addButton: 'addButton',
  cell: 'cell',
});

export type Props = Omit<CellRendererProps, 'config'> & {
  isSelected: boolean;
  toggleSelected: (id: number) => void;
  isMatched?: boolean;
  isSearchActive?: boolean;
  config?: { cellSize?: number };
  truncateLinesNumber?: number | null;
};

const BaseColumnCellRenderer: FC<Props> = ({
  entity,
  config: { cellSize } = {},
  isSelected,
  toggleSelected,
  isSearchActive,
  isMatched,
  truncateLinesNumber,
}) => {
  const { t } = useTranslation();
  const { name, id } = entity || { name: {} };
  const [hovered, setHovered] = useState(false);
  const onHoverOn = () => setHovered(true);
  const onHoverOff = () => setHovered(false);

  const toggleSelection = useCallback(() => {
    toggleSelected(id);
  }, [toggleSelected, id]);

  const { classes } = useStyles({ cellSize, isSelected, isMatched, isSearchActive });

  const text = useMemo(() => t(name.key, name.defaultValue) || name.defaultValue, [name, t]);
  const estimatedTruncateLength = useMemo(() => ((truncateLinesNumber as number) * (cellSize || 100)) / 12, []);
  if (!entity) return <span />;
  return (
    <div className={classes.cellTile} data-testid={testId.cell} onMouseEnter={onHoverOn} onMouseLeave={onHoverOff}>
      <Typography className={classes.label} component='span'>
        {truncateLinesNumber && text.length > estimatedTruncateLength ? (
          <TruncatedTextWithTooltip linesBeforeTruncate={3} text={text} tooltipProps={{ enterDelay: 1500 }} />
        ) : (
          t(name.key, name.defaultValue) || name.defaultValue
        )}
      </Typography>
      {(hovered || isMatched || isSelected) &&
        (isSelected ? (
          <RemoveItemButton className={classes.actionButton} size='small' testId={testId.removeButton} onClick={toggleSelection} />
        ) : (
          <AddItemButton className={classes.actionButton} size='small' testId={testId.addButton} onClick={toggleSelection} />
        ))}
    </div>
  );
};

export default BaseColumnCellRenderer;
