import { useMemo } from 'react';

import { useQuery } from 'react-query';

import patients from 'api/patients';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';

import { parseAddressData } from '../../_services/parseAddressData/parseAddressData';

export const useAddressQuery = () => {
  const { patient } = usePatientContext();

  const { data, isFetched } = useQuery([QUERY_KEYS.PATIENT_INFO, patient?.id], patients.getPatient(patient?.id), {
    enabled: !!patient?.id,
  });

  const addressParsedData = useMemo(() => data && parseAddressData(data.data.personal_data), [data]);

  return { isFetched, addressData: addressParsedData };
};
