import { makeStyles } from 'tss-react/mui';

interface IStylesProps {
  isActive: boolean;
  customSize: { width: string; height: string } | undefined;
}

const useStyles = makeStyles<IStylesProps>()((theme, { isActive, customSize }) => ({
  icon: {
    ...(customSize && customSize),

    '& *': {
      transition: theme.transitions.create('stroke', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(isActive ? {} : { stroke: theme.palette.grey[500] }),
    },
  },
}));

export default useStyles;
