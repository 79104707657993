import type { FC } from 'react';
import { useCallback } from 'react';

import { ButtonBase, Typography } from '@mui/material';
import { format } from 'date-fns';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';

import type { PatientPolicy } from 'api/patientPolicies/patientPolicies.types';
import FixedSizeCard from 'components/UI/atoms/FixedSizeCard/FixedSizeCard';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';
import normalizePath from 'helpers/normalizePath/normalizePath';
import patientMessages from 'translations/specific/patient.mjs';

import useStyles from './PolicyCard.styles';

export const testId = createTestIdObject('PolicyCard', {
  generalConditions: 'generalConditions',
  policyScope: 'policyScope',
});

export type Props = {
  policyData: PatientPolicy;
  redirectToServices: (id: number) => void;
};

const PolicyCard: FC<Props> = ({ policyData, redirectToServices }) => {
  const { t } = useTranslation();

  const onScopeClick = useCallback(() => {
    if (!isNil(policyData.payerId)) redirectToServices(policyData.payerId);
  }, [policyData.payerId]);

  const { classes, cx } = useStyles();
  return (
    <FixedSizeCard maxWidth={364}>
      <>
        <header className={classes.header}>
          <div>
            {policyData.payerImg.logoLeft ? (
              <img alt={t(policyData.name.key)} className={classes.headerImage} src={normalizePath(policyData.payerImg.logoLeft)} />
            ) : (
              <Typography variant='subtitle1'>{t(policyData.name.key)}</Typography>
            )}
          </div>
          <div className={classes.headerLinks}>
            <Typography
              className={cx(!policyData.payerId && classes.disabledLink)}
              color='primary'
              component={ButtonBase}
              data-testid={testId.policyScope}
              disabled={!policyData.payerId}
              disableRipple
              variant='captionBold'
              onClick={onScopeClick}
            >
              {t(patientMessages.policiesAndPaymentsSection.policyScopeLink)}
            </Typography>
            {policyData.generalConditions?.file && (
              <Typography
                color='primary'
                component='a'
                data-testid={testId.generalConditions}
                href={normalizePath(policyData.generalConditions.file)}
                target='_blank'
                variant='captionBold'
              >
                {policyData.generalConditions.name || t(patientMessages.policiesAndPaymentsSection.policy.generalConditionsLink)}
              </Typography>
            )}
          </div>
        </header>
        <div className={classes.body}>
          <div className={classes.infoBox}>
            <Typography variant='caption'>{t(patientMessages.policiesAndPaymentsSection.policy.policyNumberLabel)}</Typography>
            <Typography variant='subtitle3'>{policyData.policyNumber}</Typography>
          </div>
          <div className={classes.infoBox}>
            <Typography variant='caption'>{t(patientMessages.policiesAndPaymentsSection.policy.policyValidityTime)}</Typography>
            <Typography variant='subtitle3'>
              {format(policyData.validFrom, DATE_FORMATS.DISPLAY)} - {format(policyData.validUntil, DATE_FORMATS.DISPLAY)}
            </Typography>
          </div>
        </div>
      </>
    </FixedSizeCard>
  );
};

export default PolicyCard;
