import type { FC } from 'react';
import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import type { ParsedListData } from 'api/_parsedTypes';
import type { PaginatedApiResponse } from 'api/_types';
import type { ReportListData } from 'api/reports';
import reports from 'api/reports';
import { ReactComponent as ReportIcon } from 'assets/icons/hub/active/document.svg';
import CustomColorText from 'components/UI/atoms/CustomColorText/CustomColorText';
import DisabledWrapper from 'components/UI/atoms/DisabledWrapper/DisabledWrapper';
import PageTitle from 'components/UI/molecules/PageTitle/PageTitle';
import ReportsTableCardActions from 'components/UI/organisms/_tables/_reports/ReportsTableCardActions/ReportsTableCardActions';
import ReportsTableRowActions from 'components/UI/organisms/_tables/_reports/ReportsTableRowActions/ReportsTableRowActions';
import CustomTable from 'components/UI/organisms/_tables/CustomTable/CustomTable';
import MainContentWrapper from 'components/UI/organisms/MainContentWrapper/MainContentWrapper';
import { reportsTableHeaders } from 'constants/_tableHeaders/reportsTableHeaders';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import PATHS from 'constants/router/PATHS';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import { useTitleItemsCount } from 'hooks/useTitleItemsCount/useTitleItemsCount';
import { getCurrentRowTriageLevelTranslationArray } from 'services/_symptomChecker/getCurrentRowTriageLevelTranslationArray/getCurrentRowTriageLevelTranslationArray';
import { getTriageLevelColorPath } from 'services/_symptomChecker/getTriageLevelColorPath/getTriageLevelColorPath';
import useAppPermissionsStorage from 'storages/appPermissionsStorage';
import symptomCheckerReports from 'translations/specific/symptomCheckerReports.mjs';

const ReportPage: FC = () => {
  const { mainFunctionalitiesAllowed } = useAppPermissionsStorage();
  const { t } = useTranslation();

  const titleDataSource = useMemo(
    () => ({
      queryKey: QUERY_KEYS.REPORTS_LIST,
      queryFunction: reports.getReports,
      additionalParams: { is_hidden: false },
    }),
    [],
  );

  const itemsCount = useTitleItemsCount(titleDataSource);

  const headerLabel = useMemo(
    () =>
      itemsCount && itemsCount >= 0
        ? `${t(symptomCheckerReports.reportsList.header)} (${itemsCount})`
        : t(symptomCheckerReports.reportsList.header),
    [itemsCount, t],
  );

  const actionsRenderer = useCallback(
    ({ meta, created_at }: ParsedListData, shouldShowVerticalVersion?: boolean) =>
      shouldShowVerticalVersion ? (
        <ReportsTableCardActions
          menuData={{
            id: meta.id,
            createdAtTime: unknownDateToString(created_at, DATE_FORMATS.DISPLAY_TIME),
            isHidden: meta.is_hidden,
            isMedicalRecord: meta.is_medical_record,
            reportFileId: meta.report,
          }}
        />
      ) : (
        <ReportsTableRowActions
          createdAtTime={unknownDateToString(created_at, DATE_FORMATS.DISPLAY_TIME)}
          id={meta.id}
          isHidden={meta.is_hidden}
          isMedicalRecord={meta.is_medical_record}
          queryKey={QUERY_KEYS.REPORTS_LIST}
          reportFileId={meta.report}
        />
      ),
    [],
  );

  const dataSource = useMemo(
    () => ({
      queryKey: QUERY_KEYS.REPORTS_LIST,
      queryFunction: reports.getReports,
      additionalParams: { is_hidden: false },
    }),
    [],
  );

  const dataParser = useCallback(
    (data: PaginatedApiResponse<ReportListData>) =>
      data.data.results.map(rowData => {
        const { condition_name, created_at, triage_level } = rowData;

        return {
          id: rowData.id,
          report_name: condition_name,
          created_at: unknownDateToString(new Date(created_at), DATE_FORMATS.DISPLAY_TIME),
          recommendation: (
            <CustomColorText colorPath={getTriageLevelColorPath(triage_level)}>
              {t(getCurrentRowTriageLevelTranslationArray(triage_level))}
            </CustomColorText>
          ),
          meta: rowData,
        };
      }),
    [],
  );

  return (
    <MainContentWrapper extraContainer noPadding>
      <DisabledWrapper disabled={!mainFunctionalitiesAllowed}>
        <PageTitle
          buttonDisabled={!mainFunctionalitiesAllowed}
          buttonLabel={t(symptomCheckerReports.reportsList.buttons.diagnose)}
          headerLabel={headerLabel}
          icon={<ReportIcon />}
          redirectPath={PATHS.SYMPTOM_CHECKER}
        />
        <CustomTable
          actionsRenderer={actionsRenderer}
          dataParser={dataParser}
          dataSource={dataSource}
          headers={reportsTableHeaders}
          rowLink={({ meta }: ParsedListData) => generatePath(PATHS.REPORT_SINGLE, { id: meta.id.toString() })}
        />
      </DisabledWrapper>
    </MainContentWrapper>
  );
};

export default ReportPage;
