import type { Gender } from 'constants/_types/Gender';

export type PatientFormInputKey =
  | 'firstName'
  | 'lastName'
  | 'dateOfBirth'
  | 'hasForeignDocument'
  | 'documentType'
  | 'documentNumber'
  | 'documentCountry'
  | 'pesel'
  | 'gender'
  | 'phoneNumber'
  | 'email'
  | 'street'
  | 'city'
  | 'country'
  | 'buildingNumber'
  | 'postcode'
  | 'apartmentNumber';

export const PATIENT_FORM_INPUT_KEYS: { [key in PatientFormInputKey]: PatientFormInputKey } = {
  firstName: 'firstName',
  lastName: 'lastName',
  dateOfBirth: 'dateOfBirth',
  hasForeignDocument: 'hasForeignDocument',
  documentType: 'documentType',
  documentNumber: 'documentNumber',
  documentCountry: 'documentCountry',
  pesel: 'pesel',
  gender: 'gender',
  phoneNumber: 'phoneNumber',
  email: 'email',
  street: 'street',
  city: 'city',
  country: 'country',
  buildingNumber: 'buildingNumber',
  postcode: 'postcode',
  apartmentNumber: 'apartmentNumber',
};

export type PatientFormInput = {
  firstName: string;
  lastName: string;
  dateOfBirth: Date | string;
  hasForeignDocument: boolean;
  documentType: number | undefined;
  documentNumber: string | undefined;
  documentCountry: string | undefined;
  pesel: string | undefined;
  gender: Gender;
  phoneNumber: string;
  email: string;
  street: string;
  city: string;
  country: string;
  buildingNumber: string;
  postcode: string;
  apartmentNumber: string;
  clauses: {
    [key: string]: boolean;
  };
};
