import axios from 'axios';

import type { PaginatedApiResponse } from 'api/_types';
import type { CoreService, CoreServiceDTO } from 'api/coreService/coreService.types';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import { endpoint } from '../coreService';
import { parseServicesForFE } from '../parseServicesForFE/parseServicesForFE';

interface Params {
  patientId: number;
  types: string[];
  payers: string[];
}

type GetServicesFromSubtypeQuery = () => Promise<CoreService[]>;

// TODO check every place of usage
export const getServicesFromSubtype =
  ({ patientId, types, payers }: Params): GetServicesFromSubtypeQuery =>
  async () => {
    const { data } = await axios.get<GetServicesFromSubtypeQuery, PaginatedApiResponse<CoreServiceDTO>>(
      createUrlWithParams({
        url: endpoint,
        params: {
          patient_id: patientId,
          limit: 25,
          type: types,
          payer: payers,
        },
      }),
    );

    return parseServicesForFE(data.results);
  };
