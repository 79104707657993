import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  tabsContainer: {
    width: '100%',
    padding: theme.spacing(5, 5, 0, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2, 0, 2),
    },
  },
}));

export default useStyles;
