import type { CSSObject } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

const elements = ['tdTile', 'tdLabel'] as const;
type Element = typeof elements[number];

interface StylesProps {
  cellSize?: number;
  columnsNumber: number | undefined;
  isMatched: boolean;
  isSearchActive: boolean;
}

const useStyles = makeStyles<StylesProps>()((theme, { cellSize, columnsNumber, isSearchActive, isMatched }) => {
  const stylesForMatched: Record<Element, CSSObject> = {
    tdTile: {
      background: theme.palette.colorBackground.primary.main,
      boxShadow: theme.shadows[2],
    },
    tdLabel: {
      color: theme.palette.primary.main,
    },
  };

  const stylesForDisabled: Record<Element, CSSObject> = {
    tdTile: {
      opacity: 0.2,
      pointerEvents: 'none',
    },
    tdLabel: {},
  };

  return {
    tdTile: {
      width: columnsNumber || !cellSize ? 'unset' : `${cellSize}px`,
      minWidth: cellSize ? `${cellSize}px` : 'auto',
      background: theme.palette.grey[300],
      display: 'flex',
      height: '100%',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(0.75, 0.5),
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'left',
      border: 'none',
      ...(!isMatched && isSearchActive ? stylesForDisabled.tdTile : {}),
      ...(isMatched ? stylesForMatched.tdTile : {}),
    },
    tdLabel: {
      ...(!isMatched && isSearchActive ? stylesForDisabled.tdLabel : {}),
      ...(isMatched ? stylesForMatched.tdLabel : {}),
    },
  };
});

export default useStyles;
