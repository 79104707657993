import { useCallback, useState } from 'react';

import { DIALOGS, type Dialogs } from 'constants/_types/Dialogs';

// @deprecated
// use the state in each component instead
export const useDialogs = () => {
  const [dialog, setDialog] = useState<Dialogs | null>(null);

  const closeDialog = useCallback(() => setDialog(null), [setDialog]);

  const openPasswordChangeDialog = useCallback(() => setDialog(DIALOGS.passwordChange), [setDialog]);
  const openPatientDialog = useCallback(() => setDialog(DIALOGS.patient), [setDialog]);
  const openPrescriptionDialog = useCallback(() => setDialog(DIALOGS.prescription), [setDialog]);
  const openReferralDialog = useCallback(() => setDialog(DIALOGS.referral), [setDialog]);
  const openStationaryVisitDialog = useCallback(() => setDialog(DIALOGS.stationaryVisit), [setDialog]);
  const openTeleconsultationDialog = useCallback(() => setDialog(DIALOGS.teleconsultation), [setDialog]);

  return {
    dialog,
    closeDialog,
    openPasswordChangeDialog,
    openPatientDialog,
    openPrescriptionDialog,
    openReferralDialog,
    openStationaryVisitDialog,
    openTeleconsultationDialog,
  };
};
