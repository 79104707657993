import type { FC } from 'react';

import { Toolbar } from '@mui/material';

import { useBrandContext } from 'components/context/BrandContext/BrandContext';
import GlobalSearchBar from 'components/UI/organisms/GlobalSearchBar/GlobalSearchBar';
import UserMenuDropdown from 'components/UI/organisms/UserMenuDropdown/UserMenuDropdown';

import useStyles from './AppBarContentMobile.styles';

const AppBarContentMobile: FC = () => {
  const { brandInfo } = useBrandContext();
  const { classes, cx } = useStyles();

  return (
    <Toolbar classes={{ root: classes.toolbarRoot }}>
      <div className={classes.element}>
        {brandInfo?.brand.logo_left && <img alt={brandInfo.name} className={classes.logo} src={brandInfo.brand.logo_left} />}
      </div>
      <div className={cx(classes.element, classes.searchIcon)}>
        <GlobalSearchBar />
      </div>
      <div className={cx(classes.element, classes.elementRight)}>
        <UserMenuDropdown />
      </div>
    </Toolbar>
  );
};

export default AppBarContentMobile;
