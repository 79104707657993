import type { ChangeEvent, FC, RefObject } from 'react';
import { useMemo } from 'react';

import type { Control, ValidationRule } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { OrderServiceFormInput } from 'components/context/OrderServiceFormContext/_constants/inputKeys';
import InputFileHidden from 'components/UI/molecules/InputFileHidden/InputFileHidden';
import validationMessages from 'translations/common/validation.mjs';

export interface Props {
  acceptedFormats: string;
  control: Control<OrderServiceFormInput, any>;
  defaultValue: File[];
  inputRef: RefObject<HTMLInputElement>;
  multiple?: boolean;
  name: any;
  required?: boolean;
  rules?: ValidationRule<any>;
  onUploadedFile?: ({ target }: ChangeEvent<HTMLInputElement>) => void;
}

const FormInputFileHidden: FC<Props> = ({
  acceptedFormats,
  control,
  defaultValue,
  inputRef,
  multiple = false,
  name,
  required = false,
  rules = {},
  onUploadedFile,
}) => {
  const { t, i18n } = useTranslation();

  const rulesProp: { [key: string]: ValidationRule } = useMemo(
    () => ({ required: { value: required, message: t(validationMessages.required_consent) }, ...rules }),
    [rules, required, i18n.language],
  );

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field, fieldState }) => (
        <InputFileHidden
          acceptedFormats={acceptedFormats}
          error={fieldState.error?.message}
          inputRef={inputRef}
          multiple={multiple}
          name={field.name}
          value={field.value.filename}
          onChange={field.onChange}
          onChangeExtended={onUploadedFile}
        />
      )}
      rules={rulesProp}
    />
  );
};

export default FormInputFileHidden;
