import { makeStyles } from 'tss-react/mui';

const SUMMARY_WIDTH = 380;

const useStyles = makeStyles()(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridGap: theme.spacing(3),
    },
  },
  card: {
    padding: theme.spacing(2),
  },
  cardGrid: {
    display: 'grid',
    gridTemplateColumns: `auto ${SUMMARY_WIDTH}px`,
    gridGap: theme.spacing(5),

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'auto',
    },
  },
  main: {
    padding: theme.spacing(1, 3),
    display: 'grid',
    gridGap: theme.spacing(3),
    gridTemplateRows: 'repeat(3, 0.000001fr) auto',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  payersBar: {
    borderBottom: `thin solid ${theme.palette.grey[200]}`,
    paddingBottom: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      borderBottom: 'none',
      padding: theme.spacing(0),
    },
  },
  summary: {
    padding: theme.spacing(2),
    background: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    maxHeight: '80vh',
  },
}));

export default useStyles;
