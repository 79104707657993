import { createContext, useState, useContext, useMemo, useCallback, useEffect, type FC, type ReactNode } from 'react';

import { useQueries, useQuery } from 'react-query';

import { Patient } from 'api/_types';
import patients from 'api/patients';
import users, { type MeResponse } from 'api/users';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import useBooleanState from 'hooks/useBooleanState/useBooleanState';
import { useRefreshUserInfo } from 'hooks/useRefreshUserInfo/useRefreshUserInfo';
import authProvider from 'services/authProvider/authProvider';
import useBottomFixedContainerStorage from 'storages/bottomFixedContainerStorage';

type Props = {
  children: ReactNode;
};

export type UserInfo = {
  isAuthorized?: boolean | null;
  id: number | null;
  email: string | null;
  phoneNumber: string | null;
  hasPhoneVerified: boolean | null;
  hasFullAccount: boolean | null;
  uses2fa: boolean;
  patients: Patient[];
  hasObligatoryConsents: {
    result: boolean;
    missing: number[];
  } | null;
  originBrand: string | null;
  imageUrl?: string;
  language: string;
};

type AuthContextType = {
  userInfo: UserInfo;
  login: (params: { access: string; refresh: string }, callback?: () => void) => void;
  logout: () => void;
  refreshUserInfo: () => Promise<void>;
};

const emptyUserInfo: UserInfo = {
  isAuthorized: undefined,
  id: null,
  email: null,
  hasPhoneVerified: null,
  phoneNumber: null,
  hasFullAccount: null,
  patients: [],
  hasObligatoryConsents: null,
  originBrand: null,
  uses2fa: false,
  language: '',
};

const AuthContext = createContext({} as AuthContextType);

const parseUserDataResponse = (data: MeResponse): Omit<UserInfo, 'isAuthorized' | 'patients'> => ({
  id: data.id,
  email: data.email,
  phoneNumber: data.phone_number,
  hasPhoneVerified: data.has_phone_verified,
  hasFullAccount: data.has_full_account,
  hasObligatoryConsents: data.has_obligatory_consents,
  originBrand: data.origin_brand,
  uses2fa: data.uses_2fa,
  language: data.language,
});

const AuthContextProvider: FC<Props> = ({ children }) => {
  const { refreshUserInfo } = useRefreshUserInfo();
  const [hasToken, setHasTokenTrue, setHasTokenFalse] = useBooleanState(false);
  const [userInfo, setUserInfo] = useState<UserInfo>(emptyUserInfo);
  const { closeGlobalForm, endGlobalFormLoading } = useBottomFixedContainerStorage();

  const [{ data: userData }, { data: patientsData }] = useQueries([
    {
      queryKey: [QUERY_KEYS.USER_DATA_REFRESH],
      queryFn: users.me(),
      refetchOnWindowFocus: false,
      enabled: hasToken,
    },
    {
      queryKey: [QUERY_KEYS.PATIENTS_REFRESH],
      queryFn: patients.getPatients(),
      refetchOnWindowFocus: false,
      enabled: hasToken,
    },
  ]);

  useEffect(() => {
    if (!userData || !patientsData) return;

    const newUserInfo: UserInfo = {
      ...parseUserDataResponse(userData.data),
      isAuthorized: true,
      patients: patientsData?.data.results,
    };
    setUserInfo(newUserInfo);
  }, [userData, patientsData]);

  const onLogin = () => {
    setHasTokenTrue();
  };

  const onLogout = () => {
    setHasTokenFalse();
    closeGlobalForm();
    endGlobalFormLoading();
    setUserInfo({ ...emptyUserInfo, isAuthorized: false });
  };

  const onValidate = (validated: boolean) => {
    if (validated) onLogin();
    else onLogout();
  };

  const { data: userDataFromAPI } = useQuery(QUERY_KEYS.USER_DATA, users.me(), {
    refetchInterval: 1000 * 60 * 5,
    enabled: hasToken,
  });

  useEffect(() => {
    if (userDataFromAPI) {
      const { data } = userDataFromAPI;
      const newUserInfo = parseUserDataResponse(data);
      setUserInfo(prev => ({ ...prev, ...newUserInfo }));
    }
  }, [userDataFromAPI]);

  useEffect(() => {
    authProvider.validate(onValidate);
  }, []);

  const login = useCallback(
    (data, callback) =>
      authProvider.login(data, () => {
        onLogin();
        if (callback) callback();
      }),
    [],
  );

  const logout = useCallback(() => authProvider.logout(onLogout), []);

  // Context Interface
  const value = useMemo(
    () => ({
      userInfo,
      login,
      logout,
      refreshUserInfo,
    }),
    [userInfo, login, logout, refreshUserInfo],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthContextProvider;
