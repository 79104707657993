import type { FC } from 'react';
import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PatientCardSectionTitle from 'components/UI/atoms/PatientCardSectionTitle/PatientCardSectionTitle';
import DefaultPayerCard from 'components/UI/molecules/DefaultPayerCard/DefaultPayerCard';
import PolicyCard from 'components/UI/molecules/PolicyCard/PolicyCard';
import CardContentContainer from 'components/UI/organisms/CardContentContainer/CardContentContainer';
import PATHS from 'constants/router/PATHS';
import useUserPayersStorage from 'storages/payerStorage/userPayersStorage';
import patientMessages from 'translations/specific/patient.mjs';

import useStyles from './PatientPolicyInfo.styles';
import { usePatientPolicyQuery } from './usePatientPolicyQuery';

const PatientPolicyInfo: FC = () => {
  const { t } = useTranslation();
  const { data, isLoading } = usePatientPolicyQuery();

  const { setOnlyOnePayerActive } = useUserPayersStorage();
  const navigate = useNavigate();
  const redirectToServices = useCallback((id: number) => {
    setOnlyOnePayerActive(id);
    navigate(`/${PATHS.SERVICES}`);
  }, []);

  const { classes } = useStyles();
  return (
    <CardContentContainer isLoading={isLoading}>
      <PatientCardSectionTitle marginBottom={3} titleText={t(patientMessages.sectionTitles.policiesAndPayments)} />
      <div className={classes.cardContainer}>
        <DefaultPayerCard redirectToServices={redirectToServices} />
        {data?.map(policyData => (
          <PolicyCard key={policyData.policyNumber} policyData={policyData} redirectToServices={redirectToServices} />
        ))}
      </div>
    </CardContentContainer>
  );
};

export default PatientPolicyInfo;
