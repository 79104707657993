import { OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';

interface Params {
  patientId: number;
  serviceOrigin?: OrderServiceOrigins;
  masterType?: string;
  masterTypeIsNull?: boolean;
  payers: string[] | undefined;
}

export const prepareParamsForGetServiceSubtypes = ({ patientId, serviceOrigin, masterType, masterTypeIsNull, payers }: Params) => ({
  patient_id: patientId,
  service_origin: serviceOrigin,
  master_type: masterType ?? '',
  master_type__isnull: masterTypeIsNull,
  limit: 250,
  payer: payers ?? [],
});
