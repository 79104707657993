import type { TFunction } from 'react-i18next';

import type { CoreServiceSubtype } from 'api/coreServiceSubtypes/types';
import type { DropdownOption } from 'constants/_types/DropdownOptions';

interface Params {
  data: CoreServiceSubtype[] | undefined;
  t: TFunction;
}

export const parseServiceSubtypes = ({ data, t }: Params) => {
  if (!data?.length) return [];

  return data.map(({ id, name: { key, defaultValue } }) => {
    const name = t([key, defaultValue]) || defaultValue;

    return {
      value: name,
      label: name,
      meta: { id: `${id}` },
    };
  }) as DropdownOption[];
};
