const customPalette = {
  triage: {
    consultation: {
      main: '#4dadf7',
      contrastText: '#fafafa',
    },
    consultation_24: {
      main: '#4dadf7',
      contrastText: '#fafafa',
    },
    emergency: {
      main: '#fcc41a',
      contrastText: '#fafafa',
    },
    emergency_ambulance: {
      main: '#ff6a67',
      contrastText: '#fafafa',
    },
    self_care: {
      main: '#1ac98e',
      contrastText: '#fafafa',
    },
  },
  probability: {
    high: {
      main: '#fd6d6c',
    },
    medium: {
      main: '#fcc52d',
    },
    low: {
      main: '#1ec98f',
    },
  },
  colorBackground: {
    primary: {
      main: '#EFFAFC',
    },
  },
};

export default customPalette;
