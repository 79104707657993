import { useQuery } from 'react-query';

import patientPolicies from 'api/patientPolicies/patientPolicies';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';

export const usePatientPolicyQuery = () => {
  const { patient } = usePatientContext();
  return useQuery(QUERY_KEYS.PATIENT_POLICIES, patientPolicies.getPoliciesForPatient(patient?.id), { enabled: !!patient?.id });
};
