import type { FC } from 'react';

import { Typography } from '@mui/material';
import type { Control } from 'react-hook-form';

import type { DateInputProps } from '../FormInputDate/FormInputDate';
import FormInputDate from '../FormInputDate/FormInputDate';
import useStyles from './FormInputDateRange.styles';

export interface Props {
  dateFromInputProps?: DateInputProps;
  dateFromLabel: string;
  dateFromName: string;
  datesControl: Control<any>;
  dateUntilInputProps?: DateInputProps;
  dateUntilLabel: string;
  dateUntilName: string;
  gridArea?: string;
  validationRulesFrom: { validate: () => string | true };
  validationRulesUntil: { validate: () => string | true };
}

const FormInputDateRange: FC<Props> = ({
  dateFromInputProps = {},
  dateFromLabel,
  dateFromName,
  dateUntilInputProps = {},
  dateUntilLabel,
  dateUntilName,
  datesControl,
  gridArea,
  validationRulesFrom,
  validationRulesUntil,
}) => {
  const { classes } = useStyles({ gridArea });

  return (
    <div className={classes.dateRange}>
      <FormInputDate
        control={datesControl}
        disablePast
        inputProps={dateFromInputProps}
        label={dateFromLabel}
        name={dateFromName}
        rules={validationRulesFrom}
      />
      <Typography>-</Typography>
      <FormInputDate
        control={datesControl}
        disablePast
        inputProps={dateUntilInputProps}
        label={dateUntilLabel}
        name={dateUntilName}
        rules={validationRulesUntil}
      />
    </div>
  );
};

export default FormInputDateRange;
