import type { Theme } from '@mui/material';

export const SCROLLBAR_STYLING = (theme: Theme) => ({
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[400],
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.grey[200],
  },
});
