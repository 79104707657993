import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ areEmptyGroups: boolean }>()((theme, { areEmptyGroups }) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: areEmptyGroups ? '160px 1fr' : '160px 3fr 2fr',
    gap: theme.spacing(4),

    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'auto',
    },
  },
}));

export default useStyles;
