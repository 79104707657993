import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import coreServiceSubtypes from 'api/coreServiceSubtypes/coreServiceSubtypes';
import type { CoreServiceSubtype } from 'api/coreServiceSubtypes/types';
import coreServiceTypes from 'api/coreServiceTypes/coreServiceTypes';
import type { CoreServiceType } from 'api/coreServiceTypes/types';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import type { ColumnsDisplaySetEntities, ColumnsDisplaySetHeader } from 'constants/_types/ColumnsDisplaySetData';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import createQueryKeyFromObject from 'helpers/createQueryKeyFromObject/createQueryKeyFromObject';
import useUserPayersStorage from 'storages/payerStorage/userPayersStorage';

type PrepareData = (
  t: TFunction,
  typesData: CoreServiceType[],
  subtypesData: CoreServiceSubtype[],
) => {
  headers: ColumnsDisplaySetHeader[];
  entities: ColumnsDisplaySetEntities;
} | null;

const prepareData: PrepareData = (t, typesData, subTypesData) => {
  if (!typesData.length) return null;
  const headers: ColumnsDisplaySetHeader[] = [];
  const entities: ColumnsDisplaySetEntities = {};

  typesData
    .sort((a, b) => a.ordering - b.ordering)
    .forEach((entity: CoreServiceType) => {
      headers.push({ id: entity.id, name: entity.name });
    });

  subTypesData
    .sort((a, b) => a.ordering - b.ordering)
    .forEach((entity: CoreServiceSubtype) => {
      const { id, name, masterType } = entity;
      const current = entities[masterType as number] || [];
      entities[masterType as number] = [...current, { name, id, searchableName: t(name.key, name.defaultValue) }];
    });

  return { headers, entities };
};

const useGetServicesCatalog = (enabled: boolean) => {
  const { t } = useTranslation();
  const { patient } = usePatientContext();
  const selectedPayersList = useUserPayersStorage(state => state.getSelectedPayersList());

  const isEnabled = enabled && selectedPayersList && !!selectedPayersList.length && !!patient;

  const { data: typesData, isLoading: typesAreLoading } = useQuery(
    [QUERY_KEYS.CORE_SERVICE_TYPES, createQueryKeyFromObject({ patient: patient?.id, selectedPayersList })],
    coreServiceTypes.getServiceTypes({ payers: selectedPayersList, patientId: patient?.id }),
    {
      enabled: isEnabled,
    },
  );

  const { data: subtypesData, isLoading: subtypesAreLoading } = useQuery(
    [QUERY_KEYS.CORE_SERVICE_SUBTYPES, createQueryKeyFromObject({ patient: patient?.id, selectedPayersList })],
    coreServiceSubtypes.getServiceSubtypes({ payers: selectedPayersList, patientId: patient?.id }),
    {
      enabled: isEnabled,
    },
  );

  return { data: prepareData(t, typesData ?? [], subtypesData ?? []), isLoading: typesAreLoading || subtypesAreLoading };
};

export default useGetServicesCatalog;
