import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },

  generalTextContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    '& > span': {
      margin: theme.spacing(0, 1),
    },
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
}));

export default useStyles;
