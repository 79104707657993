import type { FC } from 'react';

import { Typography } from '@mui/material';
import type { UseFormReturn, ValidationRule } from 'react-hook-form';

import DynamicInput from 'components/UI/molecules/DynamicInput/DynamicInput';
import type { Mask } from 'components/UI/molecules/InputText/InputText';
import type { DropdownOption } from 'constants/_types/DropdownOptions';

import useStyles from './EditableDataField.styles';

export type InputType = 'text' | 'dropdown' | 'date' | 'phone' | 'autocomplete' | 'switch' | 'checkbox';

export type Inputs = {
  [key: string]: {
    label?: string;
    type: InputType;
    options?: DropdownOption[];
    inputProps?: object;
    rules?: ValidationRule<any>;
    mask?: Mask;
    required?: boolean;
    tiny?: boolean;
  };
};

export type Props = {
  editMode: boolean;
  label: string;
  valueParser?: (value: any) => any;
  form: UseFormReturn<any>;
  gridArea?: string;
  inputs: Inputs;
};

const getDefaultParser =
  (name: string) =>
  (value: { [key: string]: any }): any =>
    value[name];

const EditableDataField: FC<Props> = ({ editMode, label, valueParser, form, inputs, gridArea }) => {
  const { classes } = useStyles({ gridArea });

  if (!editMode) {
    const initialValuesAcc: { [key: string]: any } = {};
    const values = Object.keys(inputs).reduce((acc, curr) => {
      acc[curr] = form.getValues(curr);
      return acc;
    }, initialValuesAcc);

    const parser = valueParser || getDefaultParser(Object.keys(inputs)[0]);
    return (
      <div className={classes.notEditableVersionWrapper}>
        <Typography component='label' variant='body2'>
          {label}
        </Typography>
        <Typography color='secondary' component='p' variant='subtitle3'>
          {parser(values) || '-'}
        </Typography>
      </div>
    );
  }

  const props = {
    form,
    gridArea,
    inputs,
    label,
  };

  return <DynamicInput {...props} />;
};

export default EditableDataField;
