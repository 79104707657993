import { makeStyles } from 'tss-react/mui';

import { AVATAR_SIZE } from 'components/UI/atoms/Avatar/Avatar';

const useStyles = makeStyles<{ isOpen: boolean }>()((theme, { isOpen }) => ({
  root: {
    minHeight: isOpen ? '80px' : 'unset',
    width: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: isOpen ? theme.palette.background.default : theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1.5),
    paddingTop: `calc(${AVATAR_SIZE / 2}px + ${theme.spacing(1)})`,
    marginTop: `calc(${AVATAR_SIZE / 2}px + ${theme.spacing(1)})`,
    whiteSpace: 'break-spaces',
    transition: theme.transitions.create(['background', 'min-height'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
    position: 'relative',
  },
  name: {
    wordBreak: 'break-word',
  },
  avatarWrapper: {
    position: 'absolute',
    top: `-${AVATAR_SIZE / 2}px`,
    background: theme.palette.background.paper,
    padding: theme.spacing(0.5),
    borderRadius: '100%',
  },
}));

export default useStyles;
