const generalMessages = {
  ok: [ 'general>>ok', 'Ok' ],
  cancel: [ 'general>>cancel', 'Anuluj' ],
  confirm: ['general>>confirm', 'Potwierdzam'],
  yes: [ 'general>>yes', 'Tak' ],
  no: [ 'general>>no', 'Nie' ],
  showAll: [ 'general>>showMore', 'Pokaż wszystkie' ],
  more: ['general>>more', 'Więcej'],
  submit: [ 'general>>submit', 'Wyślij' ],
  accept: ['general>>accept', 'Akceptuję'],
  notAccept: ['general>>notAccept', 'Nie akceptuję'],
  uploadFiles: [ 'general>>uploadFiles', 'Przeciągnij i upuść pliki tutaj, bądź kliknij aby wybrać pliki' ],
  addMoreFiles: [ 'general>>addMoreFiles', 'Przeciągnij i upuść pliki tutaj, bądź kliknij aby dodać więcej plików' ],
  dropHere: [ 'general>>dropHere', 'Upuść tutaj' ],
  acceptedExtensions: [ 'general>>acceptedExtensions', 'Akceptowane rozszerzenia: {{extensions}}' ],
  noData: [ 'general>>noData', 'Brak danych' ],
  open: [ 'general>>open', 'Otwórz' ],
  save: [ 'general>>save', 'Zapisz' ],
  saveChanges: ['general>>saveChanges', 'Zapisz zmiany'],
  clauseAcceptation: {
    content: [ 'general>>clauseAcceptation>>content', 'Zaakceptowany przez Ciebie regulamin usługi znajduje się' ],
    link: [ 'general>>clauseAcceptation>>link', 'tutaj' ],
  },
  gender: {
    gender: [ 'general>>gender>>gender', 'Płeć' ],
    m: [ 'general>>gender>>male', 'Mężczyzna' ],
    f: [ 'general>>gender>>female', 'Kobieta' ],
  },
  address: {
    city: [ 'general>>address>>city', 'Miasto' ],
    postCode: [ 'general>>address>>postCode', 'Kod pocztowy' ],
    street: [ 'general>>address>>street', 'Ulica' ],
    buildingNumber: [ 'general>>address>>buildingNumber', 'Numer budynku' ],
    apartmentNumber: [ 'general>>address>>apartmentNumber', 'Numer mieszkania' ],
    country: [ 'general>>address>>country', 'Kraj' ],
  },
  phoneNumber: [ 'general>>phoneNumber', 'Numer telefonu' ],
  pagination: {
    next: [ 'general>>pagination>>next', 'Dalej' ],
    previous: [ 'general>>pagination>>previous', 'Wstecz' ],
  },
  pageNotFound: {
    title: ['general>>pageNotFound>>title', 'Upssss… Coś poszło nie tak.'],
    subtitle: ['general>>pageNotFound>>subtitle', 'Ale spokojnie to nie koniec świata ;)'],
    contactDescription: ['general>>pageNotFound>>contactDescription', 'Jeśli to nie przyniesie efektu skontaktuj się z naszym supportem:'],
    contactPhoneNumber: ['general>>pageNotFound>>contactPhoneNumber', '+48 22 247 20 69'], // the same number as on the footer
    backToHomepage: ['general>>pageNotFound>>backToHomepage', 'Wróc do strony głównej'],
  },
  generalInterfaceError: {
    title: ['general>>generalInterfaceError>>title', 'Upsss...'],
    header: ['general>>generalInterfaceError>>header', 'Coś poszło nie tak.'],
    subheader: ['general>>generalInterfaceError>>subheader', 'Odśwież stronę i spróbuj ponownie'],
    refreshButtonLabel: ['general>>generalInterfaceError>>refreshButtonLabel', 'Odśwież stronę'],
    contactDescription: ['general>>generalInterfaceError>>contactDescription', 'W razie dalszych problemów skontaktuj się z nami telefonicznie:'],
  },
  generalError: ['general>>generalError', 'Coś poszło nie tak...'],
  unsavedChanges: {
    title: ['general>>unsavedChanges>>title','Niezapisane dane'],
    content: ['general>>unsavedChanges>>content','Jesteś w trakcie edycji danych, twoje zmiany nie zostaną zapisanę, czy na pewno chcesz opuścić ekran?']
  },
  missingTranslation: [ 'general>>missingTranslation', 'Brakujace tłumaczenie' ],
}

export default generalMessages
