import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'grid',
    gridGap: theme.spacing(5),
    gridTemplateRows: 'auto 1fr',
  },
}));

export default useStyles;
