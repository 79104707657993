import type { FC } from 'react';
import { useEffect } from 'react';

import { Card, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { ReactComponent as ServicesIcon } from 'assets/icons/hub/active/files.svg';
import DisabledWrapper from 'components/UI/atoms/DisabledWrapper/DisabledWrapper';
import OrderServiceBasket from 'components/UI/molecules/OrderServiceBasket/OrderServiceBasket';
import PageTitle from 'components/UI/molecules/PageTitle/PageTitle';
import ServicePayersBar from 'components/UI/molecules/ServicePayersBar/ServicePayersBar';
import MainContentWrapper from 'components/UI/organisms/MainContentWrapper/MainContentWrapper';
import useAppPermissionsStorage from 'storages/appPermissionsStorage';
import useBottomFixedContainerStorage from 'storages/bottomFixedContainerStorage';
import servicesMessages from 'translations/specific/services.mjs';

import useStyles from './ServicesLayout.styles';

const ServicesLayout: FC = () => {
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const { mainFunctionalitiesAllowed } = useAppPermissionsStorage();

  const { setShouldShowMobileBasket } = useBottomFixedContainerStorage();

  const { t } = useTranslation();

  useEffect(() => {
    setShouldShowMobileBasket(true);
    return () => {
      setShouldShowMobileBasket(false);
    };
  }, []);

  const { classes, cx } = useStyles();
  return (
    <MainContentWrapper>
      <PageTitle headerLabel={t(servicesMessages.pageTitle)} icon={<ServicesIcon />} />
      <DisabledWrapper disabled={!mainFunctionalitiesAllowed}>
        <div className={classes.root}>
          {!upSm && (
            <Card className={classes.card}>
              <div className={classes.payersBar}>
                <ServicePayersBar />
              </div>
            </Card>
          )}
          <Card className={cx(classes.cardGrid, classes.card)}>
            <main className={classes.main}>
              {upSm && (
                <div className={classes.payersBar}>
                  <ServicePayersBar />
                </div>
              )}
              <Outlet />
            </main>
            {upMd && (
              <aside className={classes.summary}>
                <OrderServiceBasket showButtons />
              </aside>
            )}
          </Card>
        </div>
      </DisabledWrapper>
    </MainContentWrapper>
  );
};

export default ServicesLayout;
