import { useCallback, useMemo } from 'react';

import type { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';

import type { ParsedPatientData } from 'api/_parsedTypes';
import patients from 'api/patients';
import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import { GlobalDialogs, useGlobalDialogsContext } from 'components/context/GlobalDialogsContext/GlobalDialogsContext';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import type { PatientFormInput } from 'components/UI/organisms/PatientPersonalInfo/_constants/patientFormKeys';
import { PATIENT_FORM_INPUT_KEYS } from 'components/UI/organisms/PatientPersonalInfo/_constants/patientFormKeys';
import { useDefaultValues } from 'components/UI/organisms/PatientPersonalInfo/_hooks/useDefaultValues/useDefaultValues';
import { useFormDynamicData } from 'components/UI/organisms/PatientPersonalInfo/_hooks/useFormDynamicData/useFormDynamicData';
import getDefaultCountryName from 'components/UI/organisms/PatientPersonalInfo/_services/getDefaultCountryName/getDefaultCountryName';
import { prepareAddressDataForUpdate } from 'components/UI/organisms/PatientPersonalInfo/_services/prepareAddressDataForUpdate/prepareAddressDataForUpdate';
import { prepareDataForCreate } from 'components/UI/organisms/PatientPersonalInfo/_services/prepareDataForCreate/prepareDataForCreate';
import { preparePersonalDataForUpdate } from 'components/UI/organisms/PatientPersonalInfo/_services/preparePersonalDataForUpdate/preparePersonalDataForUpdate';
import { userDataParser } from 'components/UI/organisms/PatientPersonalInfo/_services/userDataParser/userDataParser';
import { CLAUSE_LOCATIONS } from 'constants/_types/ClauseLocations';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import comparePhoneNumbers from 'helpers/comparePhoneNumbers/comparePhoneNumbers';
import useConsentUpdate from 'hooks/_clauses/useConsentUpdate/useConsentUpdate';
import usePatientMutations from 'hooks/_mutations/usePatientMutations/usePatientMutations';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';
import useLoading from 'hooks/useLoading/useLoading';
import useMyProfileUIStorage from 'storages/myProfileUIStorage';

export interface FormDynamicData {
  hasForeignDocument: boolean;
}

type UsePatientInfoForm = () => {
  form: UseFormReturn<PatientFormInput>;
  parsedUserData: ParsedPatientData | null;
  refreshData: ({ action }: { action: 'create' | 'update' }) => Promise<void>;
  isLoading: boolean;
  isSending: boolean;
  onSubmit: () => void;
  restoreInitialData: () => void;
};

export const usePatientInfoForm: UsePatientInfoForm = () => {
  const { userInfo, refreshUserInfo } = useAuthContext();
  const { patient } = usePatientContext() || {};
  const languageCode = useLanguageCode();

  const { addToGlobalDialogQueue } = useGlobalDialogsContext();
  const { updateClausesConsents } = useConsentUpdate([CLAUSE_LOCATIONS.personalData]);
  const sending = useLoading();
  const { isPatientEditorOpen, closePatientEditor } = useMyProfileUIStorage();

  const { data, isLoading, refetch } = useQuery([QUERY_KEYS.PATIENT_INFO, patient?.id], patients.getPatient(patient?.id), {
    enabled: !!patient?.id,
  });

  const refreshData = useCallback(
    async ({ action }: { action: 'create' | 'update' }) => {
      if (action === 'create') await refreshUserInfo();
      if (action === 'update') await Promise.all([refetch(), refreshUserInfo()]);
    },
    [refreshUserInfo, refetch],
  );

  const parsedUserData = useMemo((): ParsedPatientData | null => {
    if (!data) return null;
    return userDataParser(data.data, userInfo);
  }, [data?.data, userInfo]);

  const form = useForm<PatientFormInput>({ defaultValues: { [PATIENT_FORM_INPUT_KEYS.country]: getDefaultCountryName(languageCode) } });

  const { restoreInitialData } = useDefaultValues({ parsedUserData, form, isEditMode: isPatientEditorOpen });

  useFormDynamicData(form);

  const { createPersonalDataMutation, updatePersonalDataMutation, updateAddressDataMutation, revertNumberMutation } = usePatientMutations({
    setError: form.setError,
    setValue: form.setValue,
    onLoadingEnd: sending.end,
    refreshData,
  });

  const handleSubmit: SubmitHandler<PatientFormInput> = async formData => {
    sending.start();
    const isNumberChanged = !comparePhoneNumbers(userInfo.phoneNumber, formData[PATIENT_FORM_INPUT_KEYS.phoneNumber as 'phoneNumber']);
    if (isNumberChanged) {
      try {
        const phoneVerified = await addToGlobalDialogQueue({
          type: GlobalDialogs.verifyMobileNumber,
          props: { initialNumber: formData[PATIENT_FORM_INPUT_KEYS.phoneNumber], cancelable: true },
          withPromise: true,
        });
        if (!phoneVerified) {
          closePatientEditor();
          return;
        }
        closePatientEditor();
      } catch (err: unknown) {
        if (err === 'expired') {
          await revertNumberMutation.mutateAsync(undefined);
          return;
        }
      }
    }
    if (patient) {
      await Promise.all([
        updatePersonalDataMutation.mutateAsync(preparePersonalDataForUpdate(formData)),
        updateAddressDataMutation.mutateAsync(prepareAddressDataForUpdate(formData)),
        updateClausesConsents(formData.clauses, { updateOnlyChecked: true }),
      ]);
      refreshData({ action: 'update' });
    } else {
      await createPersonalDataMutation.mutateAsync(prepareDataForCreate(formData));
      await updateClausesConsents(formData.clauses, { updateOnlyChecked: true });
    }
    sending.end();
    closePatientEditor();
  };

  const onSubmit = form.handleSubmit(handleSubmit);

  return { form, parsedUserData, onSubmit, isLoading, refreshData, isSending: sending.state, restoreInitialData };
};
