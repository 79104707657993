import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ gridArea?: string }>()((theme, { gridArea }) => ({
  notEditableVersionWrapper: {
    wordBreak: 'break-word',
    display: 'grid',
    gridGap: theme.spacing(1),
    gridArea: gridArea || 'unset',
  },
}));

export default useStyles;
