const snackbarMessages = {
  success: [ 'snackbar>>success', 'Operacja wykonana poprawnie' ],
  failure: [ 'snackbar>>failure', 'Coś poszło nie tak...' ],
  recaptchaFailure: [ 'snackbar>>recaptchaFailure', 'Problem z google recaptcha' ],
  failureDetailed: ['snackbar>>failureDetailed', 'Coś poszło nie tak, odśwież stronę i spróbuj ponownie'],
  buttons: {
    refreshPage: ['snackbar>>buttons>>refreshPage', 'Odśwież stronę'],
  }
}

export default snackbarMessages
