import { useCallback, useMemo } from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import { useBrandContext } from 'components/context/BrandContext/BrandContext';
import IconErrorAlert from 'components/UI/atoms/IconErrorAlert/IconErrorAlert';
import { MESSAGE_ORIGINS } from 'components/UI/organisms/MainContentWrapper/_types/types';
import type { Message } from 'components/UI/organisms/MainContentWrapper/_types/types';
import PATHS from 'constants/router/PATHS';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';
import useAppPermissionsStorage from 'storages/appPermissionsStorage';
import dashboardMessages from 'translations/specific/dashboard.mjs';

export const useMissingAccountFields = () => {
  const { setMainFunctionalitiesAllowed } = useAppPermissionsStorage();
  const { t } = useTranslation();
  const languageCode = useLanguageCode();
  const { userInfo } = useAuthContext();
  const { brandInfo } = useBrandContext() || {};
  const { pathname } = useLocation();

  const shouldAlertMissingAccountFields = useMemo(() => {
    if (userInfo) {
      const hasFullAccount = !!userInfo.hasFullAccount;
      // TODO refactor useAuthContext to zustand and move that logic to useAppPermissionsStorage
      setMainFunctionalitiesAllowed(hasFullAccount);
      return !hasFullAccount;
    }
    return false;
  }, [userInfo]);

  const setMissingAccountFields = useCallback(
    (tempMessages: Message[]) => {
      const getAction = () => {
        if (pathname === PATHS.MY_PROFILE) return null;
        return (
          <Link to={PATHS.MY_PROFILE}>
            <Button color='error' size='small' variant='contained'>
              {t(dashboardMessages.alerts.fillYourData.actionButton)}
            </Button>
          </Link>
        );
      };
      tempMessages.push({
        origin: MESSAGE_ORIGINS.missingAccountFields,
        content: t(dashboardMessages.alerts.fillYourData.content, {
          missingFields: brandInfo?.config.required_patient_fields.join(', '),
        }),
        severity: 'error',
        icon: <IconErrorAlert isAlternativeStyle isBoxShadow />,
        description: t(dashboardMessages.alerts.fillYourData.description, {
          missingFields: brandInfo?.config.required_patient_fields.join(', '),
        }),
        action: getAction(),
      });
      return tempMessages;
    },
    [pathname, t, brandInfo, languageCode],
  );

  return { shouldAlertMissingAccountFields, setMissingAccountFields };
};
