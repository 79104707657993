import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > div': {
      margin: theme.spacing(0, 2),
      '& label > span:last-of-type': {
        padding: 0,
      },
    },
  },
}));

export default useStyles;
