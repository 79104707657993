import { Interpolation } from '@emotion/styled';
import { alpha, Theme } from '@mui/material';

import SCROLLBAR_SIZE from 'constants/styles/SCROLLBAR_SIZE';

const prepareGlobalStyles = (theme: Theme): Interpolation<Theme> => ({
  // GLOBAL
  html: {
    scrollBehavior: 'smooth',
    fontSize: '14px',
  },
  body: {
    background: theme.palette.background.default,
  },
  a: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  [theme.breakpoints.up('md')]: {
    'a[href*="tel:"]': {
      cursor: 'default',
      pointerEvents: 'none',
    },
  },

  // INPUT NUMBER
  'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active': {
    WebkitBoxShadow: '0 0 0 30px white inset !important',
  },
  'input::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none !important',
    margin: 0,
  },
  'input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none !important',
    margin: 0,
  },

  'input[type=number]': {
    '-moz-appearance': 'textfield !important',
  },

  // SCROLL BARS
  '@media (hover: hover)': {
    '*': {
      scrollbarColor: 'rgba(180, 180, 180, 0.25) transparent',
      scrollbarWidth: 'thin',
    },
    '*::-webkit-scrollbar': {
      width: `${SCROLLBAR_SIZE.width}px`,
      height: `${SCROLLBAR_SIZE.width}px`,
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '*::-webkit-scrollbar-thumb': {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: 'rgba(180, 180, 180, 0.25)',
    },
  },

  // reCAPTCHA
  '.grecaptcha-badge': {
    visibility: 'hidden',
  },

  // DIALOGS
  '.MuiDialog-scrollBody': {
    scrollBehavior: 'smooth',
  },

  // to use default-styled icon button in calendar from date picker
  // (not able to overwrite MuiCalendarPicker class from the theme file)
  '.MuiCalendarPicker-root .MuiIconButton-root': {
    padding: '5px',
    borderRadius: '50%',
    background: 'transparent',
    color: theme.palette.secondary.main,

    '&:focus': {
      color: theme.palette.secondary.main,
    },
    '&:disabled path': {
      fill: theme.palette.grey[400],
    },
  },

  // global classes for showing element only on particular screens
  '.onlyUpSm': { [theme.breakpoints.down('sm')]: { display: 'none !important' } },
  '.onlyUpMd': { [theme.breakpoints.down('md')]: { display: 'none !important' } },
  '.onlyUpLg': { [theme.breakpoints.down('lg')]: { display: 'none !important' } },
  '.onlyUpXl': { [theme.breakpoints.down('xl')]: { display: 'none !important' } },
  '.onlyUpXXl': { [theme.breakpoints.down('xxl')]: { display: 'none !important' } },
  '.onlyDownSm': { [theme.breakpoints.up('sm')]: { display: 'none !important' } },
  '.onlyDownMd': { [theme.breakpoints.up('md')]: { display: 'none !important' } },
  '.onlyDownLg': { [theme.breakpoints.up('lg')]: { display: 'none !important' } },
  '.onlyDownXl': { [theme.breakpoints.up('xl')]: { display: 'none !important' } },
  '.onlyDownXXl': { [theme.breakpoints.up('xxl')]: { display: 'none !important' } },

  // snackbar from notistack
  '.SnackbarItem-variantError': {
    minHeight: '66px',
    backgroundColor: '#FFF !important',
    border: `1px solid ${alpha(theme.palette.error.dark, 0.5)} !important`,
    borderRadius: `${theme.shape.borderRadius} !important`,
    boxShadow: `${theme.shadows[5]} !important`,
    padding: `16px !important`,
    color: `${theme.palette.error.main} !important`,
    '& > .SnackbarItem-message': {
      padding: `0 !important`,
    },
  },
  '.SnackbarItem-variantWarning': {
    backgroundColor: '#FFF !important',
    border: `1px solid ${alpha(theme.palette.warning.main, 0.25)} !important`,
    borderRadius: `${theme.shape.borderRadius} !important`,
    boxShadow: `${theme.shadows[5]} !important`,
    padding: `16px !important`,
    '& > .SnackbarItem-message': {
      padding: `0 !important`,
    },
  },
  '.SnackbarItem-variantSuccess': {
    backgroundColor: '#FFF !important',
    border: `1px solid ${alpha(theme.palette.success.main, 0.3)} !important`,
    borderRadius: `${theme.shape.borderRadius} !important`,
    boxShadow: `${theme.shadows[5]} !important`,
    padding: `16px !important`,
    '& > .SnackbarItem-message': {
      padding: `0 !important`,
    },
  },
  '.SnackbarItem-variantInfo': {
    backgroundColor: '#FFF !important',
    border: `1px solid ${alpha(theme.palette.secondary.main, 0.35)} !important`,
    borderRadius: `${theme.shape.borderRadius} !important`,
    boxShadow: `${theme.shadows[5]} !important`,
    padding: `16px !important`,
    '& > .SnackbarItem-message': {
      padding: `0 !important`,
    },
  },
});

export default prepareGlobalStyles;
