import type { ChangeEvent, FC } from 'react';

import PhoneInput from 'react-phone-input-2';

import InputFooter from 'components/UI/atoms/InputFooter/InputFooter';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';

import useStyles from './InputPhoneNumber.styles';
import { getLocalization } from './services/getLocalization';

import 'react-phone-input-2/lib/material.css';

export type InputPhoneNumberProps = {
  label: string;
  error?: string;
  disabled?: boolean;
  helperText?: string;
  onChange: (value: string) => void;
  value: string;
  inputProps?: {
    fullWidth?: boolean;
    multiline?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
    name?: string;
  };
};

const InputPhoneNumber: FC<InputPhoneNumberProps> = ({ label, error, helperText, inputProps, ...field }) => {
  const languageCode = useLanguageCode();

  const innerOnChange = (
    value: string,
    country: string,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    formattedValue: string,
  ): void => {
    const { onChange } = field;
    onChange(formattedValue);
  };
  const { classes } = useStyles({ error: !!error });

  return (
    <div>
      <PhoneInput
        containerClass={classes.container}
        country='pl'
        countryCodeEditable={false}
        inputClass={classes.input}
        inputProps={inputProps}
        localization={getLocalization(languageCode)}
        specialLabel={label}
        value={field.value}
        onChange={innerOnChange}
      />
      <InputFooter error={error} helperText={helperText} />
    </div>
  );
};

export default InputPhoneNumber;
