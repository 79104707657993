import { makeStyles } from 'tss-react/mui';

import { APP_BAR_DESKTOP_HEIGHT } from 'constants/styles/APP_BAR_DESKTOP_HEIGHT';
import LAYOUT_SIZES from 'constants/styles/LAYOUT_SIZES';
import breakpoints from 'styles/themeParts/breakpoints';

interface StyleProps {
  isDrawerOpened: boolean;
}

const useStyles = makeStyles<StyleProps>()((theme, { isDrawerOpened }) => ({
  toolbarRoot: {
    display: 'grid',
    position: 'relative',
    gap: theme.spacing(6),
    gridTemplateColumns: '1fr auto auto',
    alignItems: 'center',
    paddingLeft: `calc(${isDrawerOpened ? LAYOUT_SIZES.SIDE_MENU.open : LAYOUT_SIZES.SIDE_MENU.closed}px + ${theme.spacing(3)})`,
    paddingTop: theme.spacing(4.25),
    paddingBottom: theme.spacing(4.25),
    height: APP_BAR_DESKTOP_HEIGHT.xxl,
    transition: theme.transitions.create('padding-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('xl')]: {
      paddingTop: theme.spacing(3.5),
      paddingBottom: theme.spacing(3.5),
      height: APP_BAR_DESKTOP_HEIGHT.xl,
    },
  },
  search: {
    width: '100%',
    maxWidth: '820px',
    justifySelf: 'center',
    alignSelf: 'flex-end',
    padding: theme.spacing(0, 4),
    margin: 0,

    [theme.breakpoints.down(breakpoints.values.searchBar)]: {
      padding: 0,
    },
  },
  serviceProvider: {
    display: 'grid',
    gridGap: theme.spacing(1),
    gridTemplateColumns: 'auto auto',
    alignItems: 'center',
    whiteSpace: 'pre-line',
  },
  logoDescription: {
    fontWeight: 600,
  },
  logo: {
    height: '24px',
  },
}));

export default useStyles;
