import axios from 'axios';

import { DefaultApiResponse, PaginatedApiResponse, Patient, PatientAvatar, PatientAvatarInput, PersonalDataUnassigned } from './_types';

const endpoints = {
  patients: 'api/v1/user-patients/',
  avatar: 'api/v1/patient-profile-picture/',
};

type GetPatientQuery = () => Promise<DefaultApiResponse<Patient>>;

type GetPatientsQuery = () => Promise<PaginatedApiResponse<Patient>>;

export type CreatePatientsInput = { personal_data: PersonalDataUnassigned; pesel: string };

type CreatePatientsQuery = (data: CreatePatientsInput) => Promise<DefaultApiResponse<Patient>>;

export type UploadFilesResponse = { attachment_ids: number[] };
export type UploadFilesQuery = (data: FormData) => Promise<DefaultApiResponse<UploadFilesResponse>>;

type GetPatientPoliciesQuery = () => Promise<DefaultApiResponse<Patient>>;

type GetAvatarQuery = () => Promise<DefaultApiResponse<PatientAvatar>>;

type CreateAvatarQuery = (data: PatientAvatarInput) => Promise<DefaultApiResponse<PatientAvatar>>;

type UpdateAvatarQuery = (data: PatientAvatarInput) => Promise<DefaultApiResponse<PatientAvatar>>;

type DeleteAvatarQuery = () => Promise<DefaultApiResponse<null>>;

const patients = {
  getPatient:
    (id: number): GetPatientQuery =>
    () =>
      axios.get<GetPatientQuery, DefaultApiResponse<Patient>>(`${endpoints.patients}${id}/`),
  getPatients: (): GetPatientsQuery => () => axios.get<GetPatientsQuery, PaginatedApiResponse<Patient>>(endpoints.patients),
  createPatient: (): CreatePatientsQuery => data => axios.post<CreatePatientsQuery, DefaultApiResponse<Patient>>(endpoints.patients, data),
  uploadFiles:
    (id: number): UploadFilesQuery =>
    data =>
      axios.patch<UploadFilesQuery, DefaultApiResponse<UploadFilesResponse>>(`${endpoints.patients}${id}/attachments/`, data),
  getPatientPolicies:
    (id: number): GetPatientPoliciesQuery =>
    () =>
      axios.get<GetPatientPoliciesQuery, DefaultApiResponse<Patient>>(`${endpoints.patients}${id}/active-policies/`),
  getPatientAvatar:
    (id: number): GetAvatarQuery =>
    () =>
      axios.get<GetAvatarQuery, DefaultApiResponse<PatientAvatar>>(`${endpoints.avatar}${id}/`),
  createPatientAvatar: (): CreateAvatarQuery => data =>
    axios.post<CreateAvatarQuery, DefaultApiResponse<PatientAvatar>>(endpoints.avatar, data),
  updatePatientAvatar:
    (id: number): UpdateAvatarQuery =>
    data =>
      axios.put<UpdateAvatarQuery, DefaultApiResponse<PatientAvatar>>(`${endpoints.avatar}${id}/`, data),
  deletePatientAvatar:
    (id: number): DeleteAvatarQuery =>
    () =>
      axios.delete<DeleteAvatarQuery, DefaultApiResponse<null>>(`${endpoints.avatar}${id}/`),
};

export default patients;
