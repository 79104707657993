import type { OrderServiceCoreServicesKinds } from 'constants/_types/OrderServiceCoreServicesKinds';
import type { SummaryListGroup, SummaryListItem } from 'constants/_types/SummaryList';

export const sortAndGroupResults = (rawResults: SummaryListItem[] | undefined): SummaryListGroup | null => {
  if (!rawResults) return null;

  const sortedResultsByOrder = rawResults.sort((a, b) => +b.order - +a.order);

  const groupedResults = sortedResultsByOrder.reduce((acc, sortedResult) => {
    if (!acc[sortedResult.coreServiceKind]) {
      acc[sortedResult.coreServiceKind] = {
        items: [],
      };
    }

    acc[sortedResult.coreServiceKind]?.items.push({ ...sortedResult });

    return acc;
  }, {} as SummaryListGroup);

  const sortedGroupKeys = Object.keys(groupedResults).sort((coreServiceKindA, coreServiceKindB) => {
    // using the first array element due to prior sorting at 'sortedResultsBySimilarity'
    const firstItemSimilarityOfCoreServiceKindB = groupedResults[coreServiceKindB as OrderServiceCoreServicesKinds]!.items[0].order;
    const firstItemSimilarityOfCoreServiceKindA = groupedResults[coreServiceKindA as OrderServiceCoreServicesKinds]!.items[0].order;

    return +firstItemSimilarityOfCoreServiceKindB - +firstItemSimilarityOfCoreServiceKindA;
  });

  return sortedGroupKeys.reduce((acc, key) => {
    acc[key as OrderServiceCoreServicesKinds] = groupedResults[key as OrderServiceCoreServicesKinds];

    return acc;
  }, {} as SummaryListGroup);
};
