import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  iconWrapper: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center',
    height: '16px',
    width: '16px',
    borderRadius: '100%',
  },
  icon: {
    width: '100%',
    height: '100%',
    minWidth: '16px',
    minHeight: '16px',
  },
}));

export default useStyles;
