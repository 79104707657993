import axios from 'axios';

import { PaginatedApiResponse } from 'api/_types';
import type { OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import { endpoint } from '../coreServiceSubtypes';
import type { CoreServiceSubtype, CoreServiceSubtypeDTO } from '../types';
import { parseServiceSubtypesForFE } from './_services/parseServiceSubtypesForFE/parseServiceSubtypesForFE';
import { prepareParamsForGetServiceSubtypes } from './_services/prepareParamsForGetServiceSubtypes/prepareParamsForGetServiceSubtypes';

export interface Params {
  patientId: number;
  serviceOrigin?: OrderServiceOrigins;
  masterType?: string;
  masterTypeIsNull?: boolean;
  payers: string[] | undefined;
}

type GetServiceSubtypesQuery = () => Promise<CoreServiceSubtype[]>;

export const getServiceSubtypes =
  ({ patientId, serviceOrigin, masterType, masterTypeIsNull, payers }: Params) =>
  async () => {
    const { data } = await axios.get<GetServiceSubtypesQuery, PaginatedApiResponse<CoreServiceSubtypeDTO>>(
      createUrlWithParams({
        url: endpoint,
        params: prepareParamsForGetServiceSubtypes({ patientId, serviceOrigin, masterType, masterTypeIsNull, payers }),
      }),
    );

    return parseServiceSubtypesForFE(data.results);
  };
