import type { FC, ReactNode } from 'react';

import { Typography } from '@mui/material';

import useStyles from './PatientDataFormSection.styles';

export type Props = {
  title: string;
  children: ReactNode | ReactNode[];
  className?: string;
};

const PatientDataFormSection: FC<Props> = ({ title, children, className }) => {
  const { cx, classes } = useStyles();
  return (
    <section className={cx(classes.root, className)}>
      <Typography component='h3' variant='h4'>
        {title}
      </Typography>
      {children}
    </section>
  );
};

export default PatientDataFormSection;
