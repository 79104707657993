import { useEffect } from 'react';

import type { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';

import type { CoreServiceSubtype } from 'api/coreServiceSubtypes/types';
import type { DropdownOption } from 'constants/_types/DropdownOptions';

interface HookParams {
  serviceTypes: DropdownOption[];
  serviceSubtypes: DropdownOption[];
  subtypeValues: string[];
  preselectedSubtypeId: string | undefined;
  refetchSubtypes: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<CoreServiceSubtype[], unknown>>;
}

export const useRefetchingSubtypesForPreselection = ({
  serviceTypes,
  serviceSubtypes,
  subtypeValues,
  preselectedSubtypeId,
  refetchSubtypes,
}: HookParams) => {
  useEffect(() => {
    const hasFetchedTypes = serviceTypes.length > 0;
    const hasFetchedSubtypes = serviceSubtypes.length > 0;
    const isSubtypeValueEmpty = !subtypeValues.length;
    const isPreselectedSubtypeIdValidated = !!preselectedSubtypeId;

    const shouldRefetchSubtypes = hasFetchedTypes && hasFetchedSubtypes && isSubtypeValueEmpty && isPreselectedSubtypeIdValidated;
    if (!shouldRefetchSubtypes) return;

    refetchSubtypes();
  }, [subtypeValues, preselectedSubtypeId]);
};
