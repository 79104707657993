const QUERY_KEYS = {
  ACTIVATE_ACCOUNT: 'Activate account',
  BRAND_INFO: 'Brand info',
  CLAUSES: 'Clauses',
  CLAUSES_LOCATION: 'Clauses location',
  CONNECTED_FIELD_INFO: 'Connected field info',
  CONTACT_FORM_CATEGORIES: 'Contact form categories',
  CONTACT_FORM_POST: 'Contact form post',
  CONSENTS: 'Consents',
  CONSENTS_CREATE: 'Consents create',
  CONSENTS_DECLINE: 'Consents decline',
  CORE_SERVICE_GROUPS: 'Core service groups',
  CORE_SERVICE_TYPES: 'Core service types',
  CORE_SERVICE_TYPE_DETAILS: 'Core service type details',
  CORE_SERVICE_SUBTYPES: 'Core service subtypes',
  CORE_SERVICE: 'Core service',
  REMOVE_SERVICES_PROFILE: 'Remove services profile',
  FAQ: 'Faq',
  GET_SERVICES_PROFILE: 'Get services profile',
  GET_SERVICES_LIST: 'Get services list',
  GET_SINGLE_SERVICES_PROFILE: 'Get single services profile',
  GLOBAL_SEARCH: 'Global search',
  LANGUAGE: 'Language',
  LOCATIONS: 'Locations',
  PATIENT_INFO: 'Patient info',
  PATIENT_POLICIES: 'Patient policies',
  PATIENTS_REFRESH: 'Patients refresh',
  PAYER: 'Payer',
  PAYERS_BY_SERVICE: 'Payers by service',
  REFERRAL_NEEDED: 'Referral needed',
  REPORTS_LIST: 'Reports list',
  SAVE_SERVICES_PROFILE: 'Save services profile',
  SHOULD_SHOW_PASSWORD_CHANGE_REMINDER: 'Should show password change reminder',
  SYMPTOM_CHECKER_TOKEN: 'Symptom checker token',
  TWO_FA: '2FA',
  USER_AVATAR_CREATE: 'Create user avatar',
  USER_AVATAR_DELETE: 'Delete user avatar',
  USER_AVATAR_GET: 'Get user avatar',
  USER_AVATAR_UPDATE: 'Update user avatar',
  USER_DATA: 'User data',
  USER_DATA_REFRESH: 'User data refresh',
  GET_SERVICES_FOR_GROUP: 'Get services for group',
};

export default QUERY_KEYS;
